import { auth } from "../firebase";
import { updateFirebase } from "./firebaseFunctions";

export const addToWishList = async (session) => {
  try {
    await updateFirebase("events", session.id, {
      wishlisted: session.data.wishlisted
        ? [...session.data.wishlisted, auth.currentUser.email]
        : [auth.currentUser.email],
    });
  } catch (err) {
    console.log("Error while adding to wishlist: ", err);
  }
};

export const removeFromWishList = async (session) => {
  try {
    await updateFirebase("events", session.id, {
      wishlisted: session.data.wishlisted.filter(
        (w) => w !== auth.currentUser.email
      ),
    });
  } catch (err) {
    console.log("Error while removing from wishlist: ", err);
  }
};
