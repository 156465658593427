import React from "react";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Link } from "react-router-dom";

export default function CartIcon({ cartLength }) {
  return (
    <Link
      className="text-white relative w-10 flex justify-center items-center"
      to="/cart"
    >
      <div>
        <ShoppingCartIcon fontSize="large" className="text-slate-500" />
        <p className="absolute top-0 right-0 bg-orange-500 rounded-full h-5 w-5 text-center justify-center flex items-center text-sm font-bold">
          {cartLength}
        </p>
      </div>
    </Link>
  );
}
