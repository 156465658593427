import React from "react";
import Header from "../components/Header/Header";
import CustomTitle from "../components/CustomTitle";
import BackButton from "../components/BackButton";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";

export default function AboutUs() {
  const navigate = useNavigate();
  return (
    <div>
      <Header currentTab="about" />
      <div className="sm:max-w-4xl m-auto flex flex-col gap-5 py-10">
        <BackButton />
        <CustomTitle text="О нас" />
        <div className="p-3">
          <p>
            Мы — команда профессионалов создающая безопасное пространств и
            объединенных общей целью - помочь людям найти гармонию и внутреннее
            равновесие. Наши семинары и группы разработаны с учетом последних
            достижений в области психологии, чтобы предложить вам поддержку
            высокого уровня на пути к личностному росту и эмоциональному
            благополучию. Наши ключевые ценности — качество, основанное на
            глубоких знаниях и опыте.
            <br />
            <br /> Мы гарантируем высокий уровень профессионализма во всем, что
            делаем, от первого контакта до достижения поставленных целей. Особое
            внимание уделяем бережному отношению и безопасности, обеспечивая
            конфиденциальность и эмоциональную поддержку на каждом этапе
            взаимодействия. Мы уважаем ваши личные границы, мнения и убеждения,
            внимательно относимся к вашим запросам и потребностям. С нами вы
            можете быть уверены в том, что каждый шаг будет сделан с
            максимальной заботой и уважением к вам.
          </p>
        </div>
        <div className="bg-[#ABC7CA] text-white p-3 rounded">
          <p className="text-2xl font-bold mb-3">Психологические семинары</p>
          <p>
            Наши онлайн-семинары предлагают уникальную возможность погрузиться в
            изучение психологических аспектов, которые вас интересуют. Мы
            проводим мероприятия на актуальные и важные темы, доступные для всех
            желающих. В ходе этих семинаров вы сможете лучше понять себя,
            разобраться в своих эмоциях и мотивах, а также узнать практические
            советы и лайфхаки, которые помогут улучшить качество жизни и
            заботиться о своем эмоциональном благополучии.
          </p>
        </div>

        <div className="bg-[#DFE3E4] p-3 rounded">
          <p className="text-2xl font-bold mb-3">
            Групповые психологические сессии
          </p>
          <p>
            Наши групповые психологические сессии предлагают уникальный формат
            работы, в котором несколько участников, под руководством одного или
            нескольких опытных психологов, совместно прорабатывают свои личные
            запросы и проблемы. Группа имеет возможность взаимодействовать друг
            с другом, делится опытом, получать и оказывать поддержку. Групповые
            психологические сессии эффективный способ для всех участников
            находить и познавать новые способы решения своих психологических
            запросов.
            <br />
            <br /> Групповые сессии направлены на решение широкого спектра
            психологических проблем и запросов, таких как снижение тревожности,
            улучшение межличностных отношений, и развитие навыков саморегуляции.
            Этот формат позволяет глубже понять себя, получить обратную связь и
            освоить эффективные стратегии для преодоления трудностей в
            безопасной и поддерживающей обстановке.
          </p>
        </div>

        <div className="bg-[#CF9F4B] text-white p-3 rounded">
          <p className="text-2xl font-bold mb-3">
            Групповые и персональные сессии для корпоративных клиентов
          </p>
          <p>
            Мы предлагаем корпоративным клиентам комплексные решения на основе
            психологии, ориентированные на:
            <br />
            <ul className="list-disc p-3">
              <li>улучшение командной работы</li>
              <li>разработку стратегий для руководителей</li>
              <li>
                повышение эффективности взаимодействия внутри команд и компании
              </li>
            </ul>
            Наши эксперты обладают более чем 15-летним опытом работы в крупных
            международных компаниях, включая топ-менеджерские позиции. Мы
            понимаем важность уникальных аспектов командной работы, выстраивания
            доверительных внутренних отношений и своевременного выявления
            проблемных зон. Наши программы адаптированы под конкретные
            потребности вашей компании, чтобы обеспечить достижение устойчивых
            результатов и укрепление корпоративной культуры.
          </p>
          <button
            className="bg-white text-black py-2 px-5 rounded mt-10 mb-3 cursor-pointer"
            onClick={() => navigate("/business")}
          >
            Подробнее
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
}
