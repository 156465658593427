export const convertCurrency = (value, to) => {
  // for production these should be taken from currency API
  const rates = {
    USD: 0.0022,
    KZT: 1,
    EUR: 0.002,
  };

  let convertedPrice = `${to} ${Math.round(parseInt(value) * rates[to])}`;

  return convertedPrice;
};
