import { createSlice } from "@reduxjs/toolkit";

export const currencySlice = createSlice({
  name: "currency",
  initialState: {
    currency: "KZT",
  },
  reducers: {
    changeCurrencyRedux: (state, action) => {
      state.currency = action.payload;
    },
  },
});

export const { changeCurrencyRedux } = currencySlice.actions;
export default currencySlice.reducer;
