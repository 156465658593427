import React from "react";
import Header from "../components/Header/Header";
import { useForm } from "react-hook-form";
import { addFirebase } from "../assets/firebaseFunctions";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import InstagramIcon from "@mui/icons-material/Instagram";
import EmailIcon from "@mui/icons-material/Email";
import Footer from "../components/Footer";

export default function Contacts() {
  const { register, handleSubmit, reset } = useForm();

  const onSubmit = async (data) => {
    const newContact = {
      ...data,
      ...{
        createdAt: new Date().toDateString(),
      },
    };

    try {
      const id = await addFirebase("contacts", newContact);
      console.log("New contact created: ", id);
      alert("Thank you for contacting us. We will get back to you soon!");
      reset();
      // send email to Vika and Maxim
    } catch (err) {
      console.log("Error creating new contact: ", err);
    }
  };

  return (
    <div>
      <Header currentTab="contacts" />
      <div className="p-10 max-w-screen-md m-auto">
        <h1 className="text-2xl font-bold mb-10">Связаться с нами</h1>
        <div className="mb-10">
          <p>
            <WhatsAppIcon className="text-gray-500" /> +7 777 327 07 77 Виктория
            <br /> <WhatsAppIcon className="text-gray-500" /> +7 777 054 28 87
            Максим
            <br />
            <InstagramIcon className="text-gray-500" />{" "}
            <a
              href="https://www.instagram.com/mv_revival?igsh=ZThqdGRoYzN1OWhw&utm_source=qr"
              target="_blank"
              className="underline"
            >
              mv_revival
            </a>
            <br />
            <EmailIcon className="text-gray-500" /> info@mv-revival.com
            <br />
            ТОО "MV Revival"
          </p>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <input
            placeholder="Имя (необязательно)"
            {...register("name", { required: false })}
            className="p-3 shadow-lg w-full mb-3 border-2 rounded border-emerald-300"
          />
          <input
            placeholder="Email*"
            type="email"
            {...register("email", { required: true })}
            className="p-3 shadow-lg w-full mb-3 border-2 rounded border-emerald-300"
          />
          <textarea
            placeholder="Сообщение*"
            {...register("message", { required: true })}
            className="p-3 shadow-lg w-full mb-3 border-2 rounded border-emerald-300"
          />
          <button
            type="submit"
            className="bg-emerald-400 text-white w-full p-3 rounded"
          >
            Отправить
          </button>
        </form>
      </div>
    </div>
  );
}
