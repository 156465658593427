import { auth } from "../../firebase";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";

export default function ProfileIcon() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      }
    });
  }, []);

  return (
    <div className="flex items-center gap-5">
      {user ? (
        <Link to={user ? "/profile" : "/"}>
          <div className="bg-white w-14 h-14 rounded-full flex justify-center items-center border-teal-100 border-4">
            <p>
              {user
                ? auth?.currentUser?.email.substring(0, 2).toUpperCase()
                : "?"}
            </p>
          </div>
        </Link>
      ) : (
        <div className="bg-white w-14 h-14 rounded-full flex justify-center items-center border-teal-100 border-4">
          <p>
            {user
              ? auth?.currentUser?.email.substring(0, 2).toUpperCase()
              : "?"}
          </p>
        </div>
      )}
    </div>
  );
}
