import React from "react";
import Header from "../../components/Header/Header";
import { useNavigate } from "react-router-dom";

export default function ConfirmEmail() {
  const navigate = useNavigate();

  return (
    <div>
      <Header />
      <div className="flex flex-col w-1/3 m-auto mt-20">
        <h2 className="text-xl text-center">
          Please check your email
          <br />
          <span>and click on the link to confirm it</span>
        </h2>

        <button
          className="bg-teal-500 text-white p-3 mt-10 rounded flex justify-center"
          onClick={() => navigate("/login")}
        >
          Back to login
        </button>
      </div>
    </div>
  );
}
