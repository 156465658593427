import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Input from "../../components/Input";
import Header from "../../components/Header/Header";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../firebase";

export default function ResetPassword() {
  const [email, setEmail] = useState("");
  const [isReset, setIsReset] = useState(false);
  const navigate = useNavigate();
  const resetPassword = () => {
    console.log("reset password");
    sendPasswordResetEmail(auth, email)
      .then(() => {
        // Password reset email sent!
        setIsReset(true);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(
          "Error while resetting password: ",
          errorCode,
          errorMessage
        );
        alert("Error while resetting password: ", errorMessage);
      });
  };
  return (
    <div>
      <Header login={true} />
      <div className="flex flex-col sm:w-1/3 w-80 m-auto mt-10 sm:mt-20">
        {!isReset && (
          <div className="flex flex-col mt-10">
            <h2 className="italic text-slate-500">Please specify your email</h2>
            <Input
              placeholder="Email"
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button
              className="bg-teal-500 text-white p-3 mt-10 rounded flex justify-center"
              onClick={resetPassword}
            >
              Reset password
            </button>
            <button
              onClick={() => navigate(-1)}
              className="p-3 rounded flex justify-center underline text-slate-500"
            >
              Back
            </button>
          </div>
        )}

        {isReset && (
          <div className="flex flex-col">
            <p>
              Please check your email for instructions to reset your password
            </p>
            <button
              className="bg-teal-500 text-white p-3 mt-10 rounded flex justify-center"
              onClick={() => navigate("/login")}
            >
              Back to login
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
