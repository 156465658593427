import React from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export default function BackButton() {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate(-1)}
      className="cursor-pointer flex gap-3 items-center justify-center bg-slate-200 p-1 sm:w-40 mb-5 w-full rounded"
    >
      <ArrowBackIcon />
      <p>Назад</p>
    </div>
  );
}
