import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import cartReducer from "./slices/cartSlice";
import reviewReducer from "./slices/reviewSlice";
import currencyReducer from "./slices/currencySlice";
import eventsReducer from "./slices/eventsSlice";
import postsReducer from "./slices/postsSlice";
import faqsReducer from "./slices/faqsSlice";

const rootReducer = combineReducers({
  cart: cartReducer,
  review: reviewReducer,
  currency: currencyReducer,
  events: eventsReducer,
  posts: postsReducer,
  faqs: faqsReducer,
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
