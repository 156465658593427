import { useState } from "react";
import Header from "../../components/Header/Header";
import { useNavigate, useLocation } from "react-router-dom";
import Input from "../../components/Input";
import {
  signInWithEmailAndPassword,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";
import { auth, provider } from "../../firebase";
import { signInWithPopup } from "firebase/auth";
import { addFirebase, readFirebase } from "../../assets/firebaseFunctions";
import GoogleBtn from "../../components/GoogleBtn";

export default function Login() {
  const navigate = useNavigate();
  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const location = useLocation();
  const from = location.state?.from || null;

  const login = () => {
    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        signInWithEmailAndPassword(auth, user.email, user.password)
          .then((userCredential) => {
            if (userCredential.user.emailVerified) {
              console.log("user logged in: ", userCredential.user.email);
              if (from && from === "cart") {
                navigate("/cart");
              } else {
                navigate("/");
              }
            } else {
              alert("Please verify your email first");
            }
          })
          .catch((error) => {
            console.log("Error: ", error);
            alert("Invalid email or password");
          });
      })
      .catch((err) => {
        console.log("Error: ", err);
        alert("Error setting auth persistence: ", err);
      });
  };

  // const [value, setValue] = useState("");

  const loginWithGoogle = () => {
    setPersistence(auth, browserLocalPersistence).then(() => {
      signInWithPopup(auth, provider)
        .then(async (data) => {
          console.log(data.user);
          if (data.user.emailVerified) {
            console.log("user logged in with google: ", data.user.email);
            await readFirebase("users").then((users) => {
              if (
                users.filter((d) => d.data.email === data.user.email).length ===
                0
              ) {
                addFirebase("users", {
                  email: data.user.email,
                  role: [
                    "marina.kim.mk90@gmail.com",
                    "vik.gorlanova@gmail.com",
                    "maxim.lazyrin@gmail.com",
                  ].includes(data.user.email)
                    ? "admin"
                    : "user",
                });
              }
            });

            navigate("/");
          } else {
            alert("Please verify your email first");
          }
        })
        .catch((err) => {
          console.error(err);
          alert("Auth error: ", err.message);
        });
    });
  };

  return (
    <div>
      <Header login={true} />
      <div className="flex flex-col sm:w-1/3 w-80 m-auto mt-5">
        <Input
          placeholder="Email"
          type="email"
          name="email"
          value={user.email}
          onChange={(e) => setUser({ ...user, email: e.target.value })}
        />
        <Input
          placeholder="Password"
          type="password"
          name="password"
          value={user.password}
          onChange={(e) => setUser({ ...user, password: e.target.value })}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              console.log(e.key);
              login();
            }
          }}
        />
        <button
          className="text-slate-500 italic text-right underline"
          onClick={() => navigate("/reset-password")}
        >
          Forgot password
        </button>
        <button
          className="bg-teal-500 text-white p-3 mt-10 rounded flex justify-center"
          onClick={login}
        >
          Login
        </button>

        <button
          onClick={() => navigate("/register")}
          className="p-3 rounded flex justify-center underline text-slate-500"
        >
          Don't have an account? Register
        </button>
        <p className="text-center mb-10 mt-5">or</p>
        <GoogleBtn
          loginWithGoogle={loginWithGoogle}
          btnText="Login with Google"
        />
      </div>
    </div>
  );
}
