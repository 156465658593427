import React from "react";
import CarouselComponent from "./Carousel";

export default function AllProducts() {
  return (
    <div>
      <CarouselComponent
        name="Психологические семинары"
        bgColor="#ABC7CA"
        textColor="white"
        type="seminar"
        client="person"
      />
      <CarouselComponent
        name="Терапевтические группы"
        bgColor="#DFE3E4"
        textColor="#808080"
        type="group"
        client="person"
      />
      <CarouselComponent
        name="Тематические групповые консультации"
        bgColor="#808080"
        textColor="white"
        type="session"
        client="person"
      />
    </div>
  );
}
