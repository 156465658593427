import React, { useEffect, useState } from "react";
import { readFirebase } from "../assets/firebaseFunctions";
import { useNavigate } from "react-router-dom";

export default function Reviews() {
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);

  useEffect(() => {
    readFirebase("events").then((data) => {
      const filtered = data?.filter(
        (d) =>
          // new Date(d.data.date).getTime() < new Date().getTime() &&
          d.data.reviews?.filter((r) => r.status === "approved").length
      );
      console.log("filtered events: ", filtered);
      setEvents(filtered);
    });
  }, []);

  return events.length ? (
    <div className="px-10 py-10">
      <h2 className="font-bold text-3xl mb-5 text-center">
        Отзывы наших клиентов
      </h2>
      {events
        .flatMap((event) => event.data.reviews)
        .filter((review) => review.status === "approved")
        .splice(0, 3)
        .map((review) => {
          return (
            <div
              key={review.id}
              className="p-3 rounded mt-3"
              style={{ backgroundColor: "#DFE3E4" }}
            >
              <p className="text-md mb-2 font-bold">
                Cессия: {review.eventName}
              </p>
              <p className="italic mb-3">{review.review}</p>
              <p>
                {review.createdByName} | {review.createdAt}
              </p>
            </div>
          );
        })}
      <div className="flex items-center justify-center mt-5">
        <button
          className="text-white w-full sm:w-1/4 rounded p-3"
          style={{ backgroundColor: "#CF9F4B" }}
          onClick={() => navigate("/reviews")}
        >
          Посмотреть все отзывы
        </button>
      </div>
    </div>
  ) : (
    ""
  );
}
