import React, { useEffect, useState } from "react";
import { readFirebase, updateFirebase } from "../../assets/firebaseFunctions";

export default function Reviews() {
  const [events, setEvents] = useState([]);

  const changeStatus = async (event, reviewIndex, status) => {
    setEvents((prev) => {
      return prev.map((e) => {
        if (e.id === event.id) {
          return {
            ...e,
            data: {
              ...e.data,
              reviews: e.data.reviews.map((r, i) =>
                i === reviewIndex ? { ...r, status: status } : r
              ),
            },
          };
        }
        return e;
      });
    });

    try {
      const res = await updateFirebase("events", event.id, {
        reviews: event.data.reviews.map((r, i) =>
          i === reviewIndex ? { ...r, status: status } : r
        ),
      });

      console.log("res", res);
    } catch (err) {
      console.error(err.message);
      alert("Error updating review status: ", err.message);
    }
  };

  useEffect(() => {
    readFirebase("events").then((data) => {
      const filtered = data.filter(
        (d) =>
          // new Date(d.data.date).getTime() < new Date().getTime() &&
          d?.data?.reviews?.length
      );
      setEvents(filtered);
    });
  }, []);

  return (
    <div>
      <p className="font-bold mb-5 text-lg">Manage reviews</p>
      {events.length ? (
        <div>
          {events.map((event, i) => {
            return (
              <div key={i} className="bg-slate-200 p-3 my-3 rounded">
                <p>{event?.data?.title}</p>
                <p className="text-slate-500 italic">
                  {event?.data?.client} | {event?.data?.type}
                </p>
                {event?.data?.reviews?.map((review, reviewIdx) => {
                  return (
                    <div
                      key={i}
                      className="bg-slate-100 p-3 my-3 rounded relative"
                    >
                      <p className="absolute right-1 top-1 bg-cyan-500 text-white rounded p-1">
                        {review?.status}
                      </p>
                      <p className="italic font-bold">{review.review}</p>
                      <p className="italic my-3">{review.createdByName}</p>
                      <button
                        className="py-1 underline text-slate-500"
                        onClick={() =>
                          changeStatus(
                            event,
                            reviewIdx,
                            review?.status === "created"
                              ? "approved"
                              : review?.status === "approved"
                              ? "rejected"
                              : "approved"
                          )
                        }
                      >
                        {review?.status === "created"
                          ? "Approve"
                          : review?.status === "approved"
                          ? "Reject"
                          : "Approve"}
                      </button>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      ) : (
        <div>No reviews</div>
      )}
    </div>
  );
}
