import { auth } from "../../firebase";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
// import CurrencySwitcher from "./CurrencySwitcher";
import { useWindowDimensions } from "../../assets/hooks/customHooks";
import "./Navbar.css";
import NavbarBig from "./NavbarBig";
import NavbarSmall from "./NavbarSmall";

export default function Navbar({ currentTab }) {
  const [current, setCurrent] = useState();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const { width } = useWindowDimensions();

  useEffect(() => {
    setCurrent(currentTab);
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      }
    });
  }, []);

  const logout = () => {
    signOut(auth)
      .then(() => {
        console.log("user logged out");
        setUser(null);
        navigate("/");
      })
      .catch((error) => {
        console.log("Error: ", error.message, error.code);
      });
  };

  return (
    <>
      {width <= 836 ? (
        <NavbarSmall current={current} user={user} logout={logout} />
      ) : (
        <NavbarBig current={current} user={user} logout={logout} />
      )}
    </>
  );
}
