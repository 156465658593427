import { Breadcrumbs } from "@material-tailwind/react";
import { Link, useLocation } from "react-router-dom";

export default function Bread() {
  const currentUrl = useLocation();
  const path = decodeURI(currentUrl.pathname);

  return (
    <div className="bg-slate-300 px-10 py-1">
      <Breadcrumbs className="gap-2">
        <Link to="/" className="opacity-60">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
          </svg>
        </Link>
        {path.split("/").map((p, i) => {
          return (
            <Link key={i} to={path.split(p)[0] + p}>
              {p.charAt(0).toUpperCase() + p.slice(1)}
            </Link>
          );
        })}
      </Breadcrumbs>
    </div>
  );
}
