import { createSlice } from "@reduxjs/toolkit";

export const postsSlice = createSlice({
  name: "posts",
  initialState: {
    posts: [],
  },
  reducers: {
    setPostsRedux: (state, action) => {
      state.posts = action.payload;
    },
    updatePostsRedux: (state, action) => {
      const index = state.posts.findIndex((e) => e.id === action.payload.id);

      state.posts[index].data = action.payload.data;
    },
  },
});

export const { setPostsRedux, updatePostsRedux } = postsSlice.actions;
export default postsSlice.reducer;
