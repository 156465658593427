import React, { useState, useEffect } from "react";
import { readFirebase } from "../../assets/firebaseFunctions";
import { auth } from "../../firebase";

export default function OrdersSection() {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    readFirebase("orders")
      .then((data) => {
        const ordersData = data.filter(
          (x) => x.data.userEmail === auth.currentUser.email
        );
        setOrders(ordersData);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div>
      <p className="font-bold mb-5 text-lg">
        {orders?.length > 0 ? "Orders history" : "You have no orders"}
      </p>

      <div className="flex flex-col gap-3">
        {orders?.length > 0 &&
          orders.map((order, index) => {
            return (
              <div key={index} className="bg-slate-200 p-5 rounded">
                <p>
                  <span className="font-bold">Order ID:</span> {order.id}
                </p>
                <p>
                  <span className="font-bold">Order date:</span>{" "}
                  {order.data.createdAt}
                </p>
                <p>
                  <span className="font-bold">Order items:</span>
                </p>
                {order.data.items.map((item, i) => {
                  return (
                    <div key={i} className="flex flex-row gap-3">
                      <p>
                        - {item.eventName} | USD {item.eventPrice}
                      </p>
                    </div>
                  );
                })}

                <div
                  style={{ borderTop: "1px solid #2d2d2d" }}
                  className="mt-3"
                >
                  <p className="font-bold text-lg">
                    Total: USD{" "}
                    {order.data.items.reduce(
                      (acc, item) => acc + parseInt(item.eventPrice),
                      0
                    )}
                  </p>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
