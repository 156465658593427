import { useEffect, useState } from "react";
import { readFirebase } from "../../assets/firebaseFunctions";
import { auth } from "../../firebase";
import GoogleIcon from "@mui/icons-material/Google";

export default function EventsSection() {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    readFirebase("orders")
      .then((data) => {
        let filtered = data.filter(
          (e) => e.data.userEmail === auth.currentUser.email
        );

        let eventIds = filtered
          .map((f) => f.data.items)
          .map((x) => x.map((y) => y.eventId))
          .flat();

        readFirebase("events").then((data) => {
          let events = data.filter(
            (e) =>
              eventIds.includes(e.id) &&
              new Date(e.data.date).getTime() > new Date().getTime()
          );
          setEvents(events);
        });
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div>
      {events?.length > 0 ? (
        <div>
          <p className="font-bold text-lg">Your upcoming events</p>
          <p className="mb-5 italic text-slate-500">
            Timezone: Almaty, Kazakhstan | 24h format
          </p>
          <div className="flex flex-col gap-3">
            {events
              .sort(
                (a, b) =>
                  new Date(a.data.date) - new Date(b.data.date) ||
                  a.data.time.substring(0, 2) - b.data.time.substring(0, 2)
              )
              .map((e, i) => {
                return (
                  <div
                    key={i}
                    className="bg-cyan-200 p-3 rounded flex flex-col sm:flex-row gap-5"
                  >
                    <div className="flex flex-col gap-1 min-w-32">
                      <div className="bg-cyan-800 flex flex-col justify-center items-center p-3 rounded text-white">
                        <p className="text-3xl font-bold">
                          {new Date(e.data.date).getDate()}{" "}
                          {new Date(e.data.date).toLocaleString("default", {
                            month: "short",
                          })}
                        </p>
                      </div>

                      <div className="bg-cyan-600 flex flex-col justify-center items-center p-3 rounded text-white">
                        <p className="text-3xl font-bold">{e.data.time}</p>
                      </div>
                    </div>
                    <div>
                      <p className="font-bold mb-3">{e.data.title}</p>
                      <p>{e.data.description}</p>

                      <div className="flex flex-col">
                        <div className="bg-teal-600 py-3 mt-3 rounded text-center text-white flex justify-center gap-3">
                          <GoogleIcon />
                          <a
                            href={e.data.link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Click to join event
                          </a>
                        </div>
                        <div className="bg-orange-600 py-3 mt-3 rounded text-center text-white flex justify-center gap-3">
                          <GoogleIcon />
                          <a
                            href={e.data.formLink}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Click to complete the questionnaire
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      ) : (
        <p className="font-bold mb-5 text-lg">You don't have upcoming events</p>
      )}
    </div>
  );
}
