import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { auth } from "../firebase";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../components/Header/Header";
import Bread from "../components/Bread";
import { updateFirebase } from "../assets/firebaseFunctions";
import { useDispatch } from "react-redux";
import { updatePostsRedux } from "../redux/slices/postsSlice";
import { storage } from "../firebase";
import {
  getDownloadURL,
  ref as storageRef,
  uploadBytes,
} from "firebase/storage";

export default function ChangePost() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [imageFile, setImageFile] = useState(null);
  const [isPublished, setIsPublished] = useState(
    state?.post?.data?.isPublished
  );
  console.log("state", state);

  const preloadedValues = {
    title: state?.post?.data?.title,
    subtitle: state?.post?.data?.subtitle,
    detailedDescription: state?.post?.data?.detailedDescription,
    createdBy: state?.post?.data?.createdBy,
    createdAt: state?.post?.data?.createdAt,
    image: state?.post?.data?.image,
    isPublished: state?.post?.data?.isPublished,
  };

  const uploadImage = async (file) => {
    if (!file) {
      alert("Please select an image");
      return null;
    }

    const imageRef = storageRef(storage, `images/${new Date().getTime()}`);
    try {
      const snapshot = await uploadBytes(imageRef, file);
      const url = await getDownloadURL(snapshot.ref);
      return url;
    } catch (error) {
      alert(error.message);
      console.log(error.message);
      return null;
    }
  };

  const { register, handleSubmit } = useForm({
    defaultValues: preloadedValues,
  });

  const onSubmit = async (data) => {
    let image;
    if (imageFile) {
      image = await uploadImage(imageFile);
      if (!image) {
        alert("Image upload failed");
        return;
      }
    } else {
      image = state?.post?.data?.image;
    }

    if (!image) {
      alert("Image upload failed");
      return;
    }

    const updatedEvent = {
      ...data,
      image,
      isPublished,
      ...{
        createdBy: auth.currentUser.email,
        createdAt: new Date().toDateString(),
      },
    };
    console.log("updated post", updatedEvent);
    updateFirebase("posts", state?.post?.id, updatedEvent);
    //update in redux
    dispatch(updatePostsRedux({ id: state?.post?.id, data: updatedEvent }));

    alert("Post updated successfully");
    navigate("/posts");
  };

  const handleImageUpload = (e) => {
    const img = e.target.files[0];
    console.log(img);
    setImageFile(img);
  };

  return (
    <div>
      <Header />
      <div className="m-10">
        <p className="font-bold mb-5 text-lg">Update post</p>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="bg-slate-300 rounded flex flex-col gap-3 p-10 mb-10"
        >
          <input
            {...register("title", { required: true })}
            placeholder="Title"
            className="p-3 rounded"
          />
          <input
            {...register("subtitle", { required: true })}
            placeholder="Subtitle"
            className="p-3 rounded"
          />
          <textarea
            {...register("detailedDescription")}
            placeholder="Detailed description"
            className="p-3 rounded"
          />
          <p>Current image:</p>
          <input
            {...register("image", { required: true })}
            placeholder="Upload image"
            className="p-3 rounded"
          />
          <div className="flex flex-col gap-3 mt-3 bg-slate-200 p-3 rounded">
            <label htmlFor="avatar" className="font-bold">
              Upload a picture:
            </label>
            <input
              placeholder="Upload image"
              className="rounded"
              id="avatar"
              type="file"
              accept="image/png, image/jpeg, image/jpg"
              onChange={handleImageUpload}
            />
          </div>
          <div className="flex flex-row gap-2 mt-3">
            <input
              type="checkbox"
              id="isPublished"
              checked={isPublished}
              onChange={(e) => setIsPublished(e.target.checked)}
            />
            <label htmlFor="isPublished">Show on posts page?</label>
          </div>
          <button
            className="bg-teal-500 text-white p-3 rounded mt-5"
            type="submit"
          >
            Update post
          </button>
        </form>
      </div>
    </div>
  );
}
