import { useForm } from "react-hook-form";
import { auth } from "../firebase";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../components/Header/Header";
import { updateFirebase } from "../assets/firebaseFunctions";
import { useDispatch } from "react-redux";
import { updateFaqsRedux } from "../redux/slices/faqsSlice";

export default function ChangeFaq() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  console.log("state", state);

  const preloadedValues = {
    question: state?.faq?.data?.question,
    answer: state?.faq?.data?.answer,
    createdBy: state?.faq?.data?.createdBy,
    createdAt: state?.faq?.data?.createdAt,
  };

  const { register, handleSubmit } = useForm({
    defaultValues: preloadedValues,
  });

  const onSubmit = async (data) => {
    const updatedEvent = {
      ...data,
      ...{
        createdBy: auth.currentUser.email,
        createdAt: new Date().toDateString(),
      },
    };
    console.log("updated post", updatedEvent);
    updateFirebase("faqs", state?.faq?.id, updatedEvent);
    //update in redux
    dispatch(updateFaqsRedux({ id: state?.faq?.id, data: updatedEvent }));

    alert("FAQ updated successfully");
    navigate("/");
  };

  return (
    <div>
      <Header />
      <div className="m-10">
        <p className="font-bold mb-5 text-lg">Update FAQ</p>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="bg-slate-300 rounded flex flex-col gap-3 p-10 mb-10"
        >
          <input
            {...register("question", { required: true })}
            placeholder="Question"
            className="p-3 rounded"
          />
          <input
            {...register("answer", { required: true })}
            placeholder="Answer"
            className="p-3 rounded"
          />
          <button
            className="bg-teal-500 text-white p-3 rounded mt-5"
            type="submit"
          >
            Update FAQ
          </button>
        </form>
      </div>
    </div>
  );
}
