import React, { useEffect, useState } from "react";
import Logo from "./Logo";
import ProfileIcon from "./ProfileIcon";
import { useSelector } from "react-redux";
import Navbar from "./Navbar";
import CartIcon from "./CartIcon";
import { useWindowDimensions } from "../../assets/hooks/customHooks";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from "../../firebase";
import { useNavigate } from "react-router-dom";
import HeaderBig from "./HeaderBig";
import HeaderSmall from "./HeaderSmall";
// import CurrencySwitcher from "./CurrencySwitcher";

export default function Header({ currentTab }) {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const cart = useSelector((state) => state.cart.products);
  const { width } = useWindowDimensions();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      }
    });
  }, []);

  const logout = () => {
    signOut(auth)
      .then(() => {
        console.log("user logged out");
        setUser(null);
        navigate("/");
      })
      .catch((error) => {
        console.log("Error: ", error.message, error.code);
      });
  };

  return width > 836 ? (
    <HeaderBig currentTab={currentTab} cart={cart} />
  ) : (
    <HeaderSmall
      currentTab={currentTab}
      cart={cart}
      user={user}
      logout={logout}
    />
  );
}
