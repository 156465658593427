import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Bread from "../../components/Bread";
import { addFirebase, readFirebase } from "../../assets/firebaseFunctions";
import { auth } from "../../firebase";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import getStripe from "../../getStripe";

export default function Payment() {
  const cart = useSelector((state) => state.cart.products);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (cart.length === 0) {
      navigate("/");
    }
  }, []);

  const createOrder = async () => {
    const orders = await readFirebase("orders");
    const orderNumber = 100 + orders.length;

    const events = await cart.map((c) => {
      return {
        eventId: c.id,
        eventName: c.data.title,
        eventPrice: c.data.price,
      };
    });
    const newOrder = {
      userEmail: auth?.currentUser?.email || email,
      total: cart.reduce((acc, item) => acc + parseInt(item.data.price), 0),
      items: events,
      status: "created",
      createdAt: new Date().toDateString(),
      orderNumber: orderNumber,
    };
    const newOrderId = await addFirebase("orders", newOrder);

    return newOrderId;
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/);
  };

  const pay = async () => {
    console.log("Processing payment...");

    if (state?.guest) {
      if (email === "" || !validateEmail(email)) {
        alert("Please enter a valid email address");
        return;
      }
    }

    let res = await createOrder();

    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout({
      lineItems: [
        // { price: process.env.REACT_APP_STRIPE_PRICE_ID, quantity: 1 },
        { price: "price_1OvcJNC8OwPE2pR3AKdMl0oe", quantity: 1 },
      ],
      mode: "payment",
      successUrl: `${window.location.origin}/order-confirmation/${res}`,
      cancelUrl: `${window.location.origin}/payment-failure`,
      customerEmail: auth?.currentUser?.email || email,
    });

    if (error) {
      console.warn("Error:", error.message);
    }
  };

  return (
    <div>
      <Header />
      <Bread />
      <div className="p-10 text-center">
        {/* <h2 className=" text-2xl font-bold my-10">
          You are about to pay <br />
          {price}
          {cart.reduce((acc, item) => acc + parseInt(item.data.price), 0)}
        </h2> */}
        <h2 className=" text-2xl font-bold my-10">
          When you click "Pay", you will be redirected to Stripe payment page
        </h2>
        <div className="flex flex-col justify-between items-center gap-3">
          {state?.guest && (
            <input
              placeholder="Specify your email"
              className="border-2 border-gray-300 p-3 rounded w-1/3"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
          )}

          <button
            className="bg-blue-500 text-white py-3 rounded w-1/3"
            onClick={pay}
          >
            Pay
          </button>
        </div>
      </div>
    </div>
  );
}
