import Header from "../../components/Header/Header";
import { useState } from "react";
import NewEvent from "./NewEvent";
import NewPost from "./NewPost";
import NewFaq from "./NewFaq";
import Reviews from "./Reviews";
import Wishlisted from "./Wishlisted";
import AllEvents from "./AllEvents";
import AllPosts from "./AllPosts";
import AllFaqs from "./AllFaqs";
import Registrations from "./Registrations";
import { useWindowDimensions } from "../../assets/hooks/customHooks";
import { style, style_sm } from "../../assets/styles/globalStyles";
import Footer from "../../components/Footer";

export default function Admin() {
  const [section, setSection] = useState("event");
  const { width } = useWindowDimensions();
  console.log(width);

  return (
    <div className="flex flex-col justify-between h-screen">
      <div>
        <Header currentTab="admin" />
        <div className="m-auto max-w-7xl">
          <h2 className="text-3xl font-bold m-10">Admin panel</h2>
          <div className="flex flex-col sm:flex-row">
            <div style={width > 756 ? style : style_sm}>
              <button
                onClick={() => setSection("event")}
                className={
                  section === "event" ? "underline font-bold" : undefined
                }
              >
                <p>Create event</p>
              </button>
              <button
                onClick={() => setSection("allevents")}
                className={
                  section === "allevents" ? "underline font-bold" : undefined
                }
              >
                <p>All events</p>
              </button>
              <button
                onClick={() => setSection("post")}
                className={
                  section === "post" ? "underline font-bold" : undefined
                }
              >
                <p>Create post</p>
              </button>
              <button
                onClick={() => setSection("allposts")}
                className={
                  section === "allposts" ? "underline font-bold" : undefined
                }
              >
                <p>All posts</p>
              </button>
              <button
                onClick={() => setSection("faq")}
                className={
                  section === "faq" ? "underline font-bold" : undefined
                }
              >
                <p>Create FAQ</p>
              </button>
              <button
                onClick={() => setSection("allfaqs")}
                className={
                  section === "allfaqs" ? "underline font-bold" : undefined
                }
              >
                <p>All FAQs</p>
              </button>
              <button
                onClick={() => setSection("review")}
                className={
                  section === "review" ? "underline font-bold" : undefined
                }
              >
                <p>Manage reviews</p>
              </button>
              <button
                onClick={() => setSection("wishlisted")}
                className={
                  section === "wishlisted" ? "underline font-bold" : undefined
                }
              >
                <p>Wishlisted</p>
              </button>
              <button
                onClick={() => setSection("registrations")}
                className={
                  section === "registrations"
                    ? "underline font-bold"
                    : undefined
                }
              >
                <p>Registrations</p>
              </button>
            </div>
            <div className="bg-slate-100 sm:w-3/4 w-full p-10">
              {section === "event" ? (
                <NewEvent />
              ) : section === "post" ? (
                <NewPost />
              ) : section === "faq" ? (
                <NewFaq />
              ) : section === "wishlisted" ? (
                <Wishlisted />
              ) : section === "allevents" ? (
                <AllEvents />
              ) : section === "allposts" ? (
                <AllPosts />
              ) : section === "allfaqs" ? (
                <AllFaqs />
              ) : section === "registrations" ? (
                <Registrations />
              ) : (
                <Reviews />
              )}
            </div>
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}
