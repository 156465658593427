import React, { useState, useEffect } from "react";
import { auth } from "../../firebase";
import { deleteUser, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";

export default function AccountSection() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user.email);
      }
    });
  }, []);
  const navigate = useNavigate();

  const deleteAccount = () => {
    deleteUser(auth.currentUser)
      .then(() => {
        console.log("User deleted");
        alert(
          "You successfully deleted your account. We are sorry to see you go."
        );
        navigate("/");
      })
      .catch((error) => {
        console.log("Error: ", error);
        alert("Error deleting the account: ", error.message);
      });
  };

  return (
    <div>
      <p className="font-bold mb-5 text-lg">Information about your account</p>
      <p>
        <span className="font-bold">You are registered with email:</span>
        <span className="italic"> {user && user}</span>
      </p>
      <button className="text-red-500 my-10 underline" onClick={deleteAccount}>
        Delete account
      </button>
    </div>
  );
}
