import React from "react";
import { useForm } from "react-hook-form";
import { auth } from "../../firebase";
import { addFirebase } from "../../assets/firebaseFunctions";

export default function NewFaq() {
  const { register, handleSubmit, reset } = useForm();

  const onSubmit = async (data) => {
    const newFaq = {
      ...data,
      ...{
        createdBy: auth.currentUser.email,
        createdAt: new Date().toDateString(),
      },
    };

    try {
      const id = await addFirebase("faqs", newFaq);
      console.log("faq created", id);
      alert("FAQ created successfully!");
      reset();
    } catch (err) {
      console.log("Error creating the faq: ", err.message);
    }
  };

  return (
    <div>
      <p className="font-bold mb-5 text-lg">Create a FAQ</p>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col bg-slate-300 gap-3 p-10 mb-10"
      >
        <input
          {...register("question", { required: true })}
          placeholder="Question"
          className="p-3 rounded"
        />
        <input
          {...register("answer", { required: true })}
          placeholder="Answer"
          className="p-3 rounded"
        />
        <button
          className="bg-teal-500 text-white p-3 rounded mt-5"
          type="submit"
        >
          Publish
        </button>
      </form>
    </div>
  );
}
