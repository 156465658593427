import { useEffect, useState } from "react";
import { readFirebase, updateFirebase } from "../../assets/firebaseFunctions";
import { auth } from "../../firebase";
import MapsUgcIcon from "@mui/icons-material/MapsUgc";
import { useForm } from "react-hook-form";

export default function PastEventsSection() {
  const { register, handleSubmit, reset } = useForm();
  const [events, setEvents] = useState([]);
  const [event, setEvent] = useState(null);
  const [isReview, setIsReview] = useState(false);

  useEffect(() => {
    readFirebase("orders")
      .then((data) => {
        let filtered = data.filter(
          (e) => e.data.userEmail === auth.currentUser.email
        );

        let eventIds = filtered
          .map((f) => f.data.items)
          .map((x) => x.map((y) => y.eventId))
          .flat();

        readFirebase("events")
          .then((data) => {
            let events = data.filter(
              (e) =>
                eventIds.includes(e.id) &&
                new Date(e.data.date).getTime() < new Date().getTime()
            );

            setEvents(events);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  }, []);

  const onSubmit = async (data) => {
    console.log("submitting new review");
    const newReview = {
      ...data,
      ...{
        createdBy: auth.currentUser.email,
        createdByName: data.createdByName || "Anonymous",
        createdAt: new Date().toDateString(),
        eventId: event.id,
        eventName: event.data.title,
        status: "created",
        type: "specific",
      },
    };

    setIsReview(false);

    try {
      await updateFirebase("events", event.id, {
        reviews: event.data.reviews
          ? [...event.data.reviews, newReview]
          : [newReview],
      });

      alert("Thank you for submitted review!");
      reset();
      setEvent(null);
      setIsReview(false);
      window.location.reload();
    } catch (err) {
      console.log(err);
      alert("Error submitting review: ", err.message);
    }
  };

  const leaveReview = (e) => {
    console.log("leaving review", e);
    setEvent(e);
    setIsReview(true);
  };

  const deleteReview = async (e) => {
    try {
      await updateFirebase("events", e.id, {
        reviews: e.data.reviews
          ? e.data.reviews.filter((r) => r.createdBy !== auth.currentUser.email)
          : [],
      });
      alert("Review deleted successfully");
      window.location.reload();
    } catch (err) {
      console.error("Error deleting review: ", err.message);
      alert("Error deleting review: ", err.message);
    }
  };

  return (
    <div>
      {events?.length > 0 ? (
        <div>
          <p className="font-bold text-lg">Your past events</p>
          <p className="mb-5 italic text-slate-500">
            Timezone: Almaty, Kazakhstan | 24h format
          </p>
          <div className="flex flex-col gap-3">
            {events
              .sort(
                (a, b) =>
                  new Date(a.data.date) - new Date(b.data.date) ||
                  a.data.time.substring(0, 2) - b.data.time.substring(0, 2)
              )
              .map((e, i) => {
                return (
                  <div key={i} className="bg-cyan-200 p-3 rounded flex gap-5">
                    <div className="flex flex-col gap-1">
                      <div className="bg-cyan-800 flex flex-col justify-center items-center p-3 rounded text-white">
                        <p className="text-3xl font-bold text-center">
                          {new Date(e.data.date).getDate()}{" "}
                          {new Date(e.data.date).toLocaleString("default", {
                            month: "short",
                          })}
                        </p>
                      </div>

                      <div className="bg-cyan-600 flex flex-col justify-center items-center p-3 rounded text-white">
                        <p className="text-3xl font-bold">{e.data.time}</p>
                      </div>
                    </div>
                    <div>
                      <p className="font-bold mb-3">{e.data.title}</p>
                      <p>{e.data.description}</p>

                      {e.data.reviews?.length > 0 ? (
                        <div className="my-5 bg-teal-200 p-3 wounded">
                          <p className="font-bold">
                            You submitted below review:
                          </p>
                          <p className="italic">{e.data.reviews[0].review}</p>
                          <p>{e.data.reviews[0]?.createdByName}</p>
                          <button
                            onClick={() => deleteReview(e)}
                            className="mt-3 underline text-red-500"
                          >
                            Delete
                          </button>
                        </div>
                      ) : isReview ? (
                        <form
                          onSubmit={handleSubmit(onSubmit)}
                          className="flex flex-col gap-3 my-5 p-5 rounded bg-teal-300"
                        >
                          <textarea
                            className="p-3 rounded"
                            placeholder="Type here..."
                            {...register("review", { required: true })}
                          />
                          <input
                            className="p-3 rounded"
                            placeholder="Your name (optional)"
                            {...register("createdByName", { required: false })}
                          />
                          <button
                            type="submit"
                            className="text-white bg-teal-500 p-3 rounded"
                          >
                            Submit
                          </button>
                        </form>
                      ) : (
                        <button
                          onClick={() => leaveReview(e)}
                          className="bg-green-600 py-3 px-5 mt-3 rounded text-center text-white flex justify-center gap-3"
                        >
                          <MapsUgcIcon />
                          Leave a review
                        </button>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      ) : (
        <p className="font-bold mb-5 text-lg">You don't have past events</p>
      )}
    </div>
  );
}
