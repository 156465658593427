import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Header from "../components/Header/Header";
import { useDispatch, useSelector } from "react-redux";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import { addFirebase } from "../assets/firebaseFunctions";
import NotificationsIcon from "@mui/icons-material/Notifications";
import BackupIcon from "@mui/icons-material/Backup";
import { auth } from "../firebase";
import {
  usePrice,
  useSessions,
  useUserEvents,
} from "../assets/hooks/customHooks";
import BackButton from "../components/BackButton";
import { addToWishList, removeFromWishList } from "../assets/sessionFunctions";
import {
  addToWishListRedux,
  removeFromWishListRedux,
} from "../redux/slices/eventsSlice";
import { useForm } from "react-hook-form";
import moment from "moment";

export default function SessionPage() {
  const { register, handleSubmit, reset } = useForm();
  const [session, setSession] = useState();
  const dispatch = useDispatch();
  const { sessionId } = useParams();
  const { sessions } = useSessions();
  const { price } = usePrice(session && session.data.price);
  const cart = useSelector((state) => state.cart.products);
  const [isInterested, setIsInterested] = useState(false);

  const { userEvents } = useUserEvents();

  const onSubmit = async (data) => {
    const newContact = {
      ...data,
      ...{
        createdAt: new Date().toDateString(),
        session: session,
      },
    };

    try {
      const id = await addFirebase("contacts", newContact);
      console.log("New contact created: ", id);
      alert("Спасибо за вашу заявку. Мы скоро с вами свяжемся!");
      reset();
      setIsInterested(false);
      // send email to Vika and Maxim
    } catch (err) {
      console.log("Error creating new contact: ", err);
    }
  };

  useEffect(() => {
    setSession(sessions.filter((x) => x.id === sessionId)[0]);
    console.log("session", sessions.filter((x) => x.id === sessionId)[0]);
  }, [sessionId, sessions]);

  const wishlist = (session) => {
    if (auth.currentUser) {
      addToWishList(session);
      dispatch(
        addToWishListRedux({ id: session.id, user: auth.currentUser.email })
      );
    } else {
      alert("Please login first");
    }
  };

  const unwishlist = (session) => {
    removeFromWishList(session);
    dispatch(
      removeFromWishListRedux({ id: session.id, user: auth.currentUser.email })
    );
  };

  return (
    <div>
      <Header />
      <div className="sm:w-1/2 w-full m-auto flex flex-col gap-5 justify-start items-start py-10 ">
        <BackButton />
        <div className="w-full mx-auto flex flex-col gap-3 bg-slate-100 rounded py-10">
          <h1 className="text-3xl font-bold text-slate-700 text-center sm:text-left">
            Детали семинара
          </h1>
          <div className="flex flex-col gap-3 px-10">
            <h2 className="font-bold text-xl">{session?.data.title}</h2>
            <p>{session?.data?.description}</p>

            {session?.data?.detailedDescription ? (
              <div className="mt-3">
                <p className="font-bold">Обзор семинара:</p>
                {session?.data?.detailedDescription
                  .split("-")
                  .splice(1)
                  .map((x, i) => (
                    <p key={i}>- {x}</p>
                  ))}
              </div>
            ) : (
              ""
            )}

            <p className="mt-3">
              <span className="font-bold">Место проведения:</span>{" "}
              {session?.data.location}
            </p>
            <p>
              <span className="font-bold">Спикер:</span> {session?.data.speaker}
            </p>
            <p>
              <span className="font-bold">Когда:</span>{" "}
              {session?.data?.isSoon
                ? "Скоро"
                : new Date(session?.data?.date).toLocaleDateString("ru-RU", {
                    day: "2-digit",
                    month: "long",
                  })}
            </p>
            {!session?.data?.isSoon && (
              <p>
                <span className="font-bold">Время:</span> {session?.data?.time}{" "}
                (время Алматы, GMT +5)
              </p>
            )}
            <p>
              <span className="font-bold">Длительность:</span>{" "}
              {session?.data.duration}
            </p>
            <p>
              <span className="font-bold">
                {session?.data?.additionalInfo || ""}
              </span>
              {session?.data?.additionalInfoLink && (
                <a
                  href={session?.data?.additionalInfoLink}
                  className="underline text-sky-500"
                >
                  {session?.data?.additionalInfoLink}
                </a>
              )}
            </p>
            <p className="mt-3 text-xl">
              <span className="font-bold">Цена: </span>

              {session?.data?.isFree === "true" ||
              session?.data?.isFree === true
                ? "Бесплатно"
                : price}
            </p>
            <p className="italic text-slate-500">
              (осталось мест: {session?.data.slots})
            </p>
            {cart &&
            cart.length > 0 &&
            cart.map((c) => c.id).includes(sessionId) ? (
              <div>
                <Link to="/cart">
                  <button className="bg-cyan-500 rounded py-3 mt-5 text-white sm:w-1/2 w-full flex justify-center items-center">
                    <LocalMallIcon className="mr-3" />
                    Перейти в корзину
                  </button>
                </Link>
                <Link
                  className="bg-slate-500 rounded py-3 mt-3 text-white sm:w-1/2 w-full flex justify-center items-center"
                  to="/sessions"
                >
                  Смотреть все
                </Link>
              </div>
            ) : userEvents?.includes(sessionId) ? (
              <div>
                <button
                  disabled
                  style={{ backgroundColor: "#DFE3E4" }}
                  className="rounded py-3 my-5 sm:w-1/2 w-full flex justify-center items-center"
                >
                  <p style={{ color: "#808080" }}>Вы купили</p>
                </button>
              </div>
            ) : parseInt(session?.data.slots) === 0 ? (
              <div className="flex gap-3">
                <div className="bg-slate-200 w-1/2 text-slate-500 p-2 rounded my-3 text-center">
                  Все раскупили 🥲
                </div>
                <div
                  onClick={
                    session?.data.wishlisted?.filter(
                      (x) => x === auth?.currentUser?.email
                    ).length > 0
                      ? () => unwishlist(session)
                      : () => wishlist(session)
                  }
                  className="cursor-pointer bg-indigo-500 w-1/2 text-white  p-2 rounded my-3 text-center"
                >
                  {session?.data.wishlisted?.filter(
                    (x) => x === auth?.currentUser?.email
                  ).length > 0
                    ? "Remove from wish list"
                    : "Add to wish list"}
                </div>
              </div>
            ) : !isInterested ? (
              <div className="flex flex-col items-center mb-3">
                <button
                  className="bg-teal-500 rounded py-3 mt-5 text-white sm:w-1/2 w-full flex justify-center items-center"
                  // onClick={() => dispatch(addToCart(session))}
                  // onClick={() => navigate("/contacts")}
                  onClick={() => setIsInterested(true)}
                >
                  {/* <LocalMallIcon className="mr-3" />В корзину */}
                  <NotificationsIcon className="mr-3" />
                  {session?.data?.isSoon
                    ? "Сообщить дату старта"
                    : "Зарегистрироваться"}
                </button>
              </div>
            ) : (
              <div className="flex flex-col items-center mb-3">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <input
                    placeholder="Имя (опционально)"
                    {...register("name", { required: false })}
                    className="p-3 shadow-lg w-full mb-3 border-2 rounded border-emerald-300"
                  />
                  <input
                    placeholder="Email*"
                    type="email"
                    {...register("email", { required: true })}
                    className="p-3 shadow-lg w-full mb-3 border-2 rounded border-emerald-300"
                  />
                  <textarea
                    placeholder="Сообщение (опционально)"
                    {...register("message")}
                    className="p-3 shadow-lg w-full mb-3 border-2 rounded border-emerald-300"
                  />
                  <div className="flex flex-row gap-3">
                    <button
                      className="bg-slate-400 text-white w-full p-3 rounded flex justify-center items-center"
                      onClick={() => setIsInterested(false)}
                    >
                      <p>Отменить</p>
                    </button>
                    <button
                      type="submit"
                      className="bg-emerald-400 text-white w-full p-3 rounded flex justify-center items-center"
                    >
                      <BackupIcon className="mr-3" />
                      <p>
                        {session?.data?.isSoon ||
                        session?.data?.isSoon === "true"
                          ? "Оставить заявку"
                          : "Зарегистрироваться"}
                      </p>
                    </button>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
