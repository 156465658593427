import React from "react";
import SessionList from "./SessionList";
import { useNavigate } from "react-router-dom";
import CustomButton from "../CustomButton";
import CustomTitle from "../CustomTitle";

export default function Upcoming({ cart }) {
  const navigate = useNavigate();

  return (
    <div
      className={`${!cart && "px-10"} py-10`}
      style={{ backgroundColor: "#DFE3E4" }}
    >
      <CustomTitle
        text={cart ? "Вас может заинтересовать" : "Ближайшие события"}
      />
      <SessionList cart={true} />
      {!cart && (
        <CustomButton
          text="Посмотреть все семинары"
          onClick={() => navigate("/sessions")}
        />
      )}
    </div>
  );
}
