import { usePrice } from "../assets/hooks/customHooks";

export default function CartItem({ item, removeFn }) {
  const { price } = usePrice(item.data.price);

  return (
    <div
      key={item.id}
      className="p-10 flex flex-col lg:flex-row justify-between w-full gap-5"
      style={{ borderBottom: "1px solid #dedede" }}
    >
      <div className="relative">
        <p className="font-bold mb-2 text-lg">{item.data.title}</p>
        <p className="mb-2">{item.data.description}</p>
        <p className="mb-2 text-slate-500">
          Когда: {item.data.date} | {item.data.time}
        </p>
        <button onClick={removeFn} className="text-orange-500 my-5 underline">
          Remove
        </button>
      </div>
      <div>
        <p className="font-bold text-lg whitespace-nowrap">{price}</p>
      </div>
    </div>
  );
}

export function PaymentItem({ item }) {
  const { price } = usePrice(item.data.price);
  return (
    <div
      key={item.id}
      className="flex justify-between mb-2 pb-3"
      style={{ borderBottom: "1px solid #dedede" }}
    >
      <p className="w-2/3">{item.data.title}</p>
      <p className="w-1/3 text-right">{price}</p>
    </div>
  );
}
