import Header from "../../components/Header/Header";
import Bread from "../../components/Bread";
import { useState } from "react";
import AccountSection from "./AccountSection";
import EventsSection from "./EventsSection";
import OrdersSection from "./OrdersSection";
import Footer from "../../components/Footer";
import PastEventsSection from "./PastEventsSection";
import WishlistSection from "./WishlistSection";
import { useWindowDimensions } from "../../assets/hooks/customHooks";
import { style, style_sm } from "../../assets/styles/globalStyles";

export default function Profile() {
  const [section, setSection] = useState("account");
  const { width } = useWindowDimensions();

  return (
    <div className="flex flex-col justify-between h-screen">
      <div>
        <Header />
        <div className="max-w-7xl m-auto">
          <h2 className="text-3xl font-bold m-10">My profile</h2>
          <div className="flex flex-col sm:flex-row">
            <div style={width > 756 ? style : style_sm}>
              <button
                onClick={() => setSection("account")}
                className={
                  section === "account" ? "underline font-bold" : undefined
                }
              >
                <p>Account</p>
              </button>
              <button
                onClick={() => setSection("events")}
                className={
                  section === "events" ? "underline font-bold" : undefined
                }
              >
                <p>Upcoming events</p>
              </button>
              <button
                onClick={() => setSection("pastevents")}
                className={
                  section === "pastevents" ? "underline font-bold" : undefined
                }
              >
                <p>Past events</p>
              </button>
              <button
                onClick={() => setSection("orders")}
                className={
                  section === "orders" ? "underline font-bold" : undefined
                }
              >
                <p>Order history</p>
              </button>
              <button
                onClick={() => setSection("wishlist")}
                className={
                  section === "wishlist" ? "underline font-bold" : undefined
                }
              >
                <p>Wishlist</p>
              </button>
            </div>
            <div className="bg-slate-100 sm:w-3/4 w-full p-10">
              {section === "account" ? (
                <AccountSection />
              ) : section === "events" ? (
                <EventsSection />
              ) : section === "pastevents" ? (
                <PastEventsSection />
              ) : section === "wishlist" ? (
                <WishlistSection />
              ) : (
                <OrdersSection />
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
