import { useNavigate } from "react-router-dom";

export default function NavbarBig({ user, current, logout }) {
  const navigate = useNavigate();

  return (
    <div className="flex justify-between items-center gap-3">
      {[
        "marina.kim.mk90@gmail.com",
        "vik.gorlanova@gmail.com",
        "maxim.lazyrin@gmail.com",
      ].includes(user?.email) && (
        <button
          onClick={() => navigate("/admin")}
          className={`${
            current === "admin" ? "underline font-bold" : undefined
          } navbar-text`}
        >
          Админ
        </button>
      )}
      <button
        onClick={() => navigate("/")}
        className={`flex navbar-text min-w-10 whitespace-nowrap ${
          current === "main" ? "underline font-bold" : undefined
        }`}
      >
        Главная
      </button>
      <button
        onClick={() => navigate("/about")}
        className={`flex navbar-text min-w-10 whitespace-nowrap ${
          current === "about" ? "underline font-bold" : undefined
        }`}
      >
        О нас
      </button>
      <button
        onClick={() => navigate("/sessions")}
        className={`navbar-text ${
          current === "events" ? "underline font-bold" : undefined
        }`}
      >
        События
      </button>
      <button
        onClick={() => navigate("/business")}
        className={`flex navbar-text min-w-10 whitespace-nowrap ${
          current === "business" ? "underline font-bold" : undefined
        }`}
      >
        Для бизнеса
      </button>
      <button
        onClick={() => navigate("/contacts")}
        className={`navbar-text ${
          current === "contacts" ? "underline font-bold" : undefined
        }`}
      >
        Контакты
      </button>

      <button
        onClick={user ? logout : () => navigate("/login")}
        className={`navbar-text ${
          current === "login" ? "underline font-bold" : undefined
        }`}
      >
        {user ? "Выйти" : "Войти"}
      </button>
    </div>
  );
}
