import { useSelector, useDispatch } from "react-redux";
import { auth } from "../../firebase";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { removeFromWishListRedux } from "../../redux/slices/eventsSlice";
import { removeFromWishList } from "../../assets/sessionFunctions";

export default function WishlistSection() {
  const dispatch = useDispatch();
  const events = useSelector((state) => state.events.events);

  const remove = async (session) => {
    dispatch(
      removeFromWishListRedux({
        id: session.id,
        user: auth.currentUser.email,
      })
    );

    removeFromWishList(session);
  };

  return (
    <div>
      <p className="font-bold mb-5 text-lg">Your wishlist</p>
      {events &&
      events.filter((e) => e.data?.wishlisted?.includes(auth.currentUser.email))
        .length > 0 ? (
        events
          .filter((e) => e.data?.wishlisted?.includes(auth.currentUser.email))
          .map((e) => {
            return (
              <div className="flex gap-3 items-center">
                <FavoriteIcon />
                <p>{e.data.title}</p>
                <button
                  onClick={() => remove(e)}
                  className="underline text-red-500 py-3"
                >
                  Remove from wishlist
                </button>
              </div>
            );
          })
      ) : (
        <p>No wishlist items</p>
      )}
    </div>
  );
}
