import React from "react";
import { Carousel } from "react-bootstrap";
import { useSessions } from "../../assets/hooks/customHooks";
import SessionCardCarousel from "../Upcoming/SessionCardCarousel";

export default function CarouselComponent({
  name,
  bgColor,
  textColor,
  first,
  type,
  client,
}) {
  const { sessions } = useSessions();

  return sessions.filter(
    (s) =>
      s.data.type === type && s.data.client === client && s.data.isPublished
  ).length === 0 ? (
    ""
  ) : (
    <div
      className={`sm:p-10 py-5 ${first && "mt-5"}`}
      style={{ backgroundColor: bgColor }}
    >
      {sessions.filter((s) => s.data.client === "company").length > 0 &&
        first === true && (
          <i>
            <p
              style={{
                fontSize: "30px",
                marginBottom: "2rem",
                color: "#DFE3E4",
              }}
            >
              Продукты для корпоративных клиентов
            </p>
          </i>
        )}
      <h2
        className="font-bold text-3xl mb-5 text-center"
        style={{ color: textColor }}
      >
        {name}
      </h2>
      <Carousel>
        {sessions
          .filter((s) => s.data.type === type && s.data.client === client)
          .map((session) => (
            <Carousel.Item key={session.id}>
              <div className="flex justify-center">
                <SessionCardCarousel sessionData={session} />
              </div>
            </Carousel.Item>
          ))}
      </Carousel>
    </div>
  );
}
