import { useEffect, useState } from "react";
import { readFirebase } from "../../assets/firebaseFunctions";

export default function Registrations() {
  const [registrations, setRegistrations] = useState([]);

  useEffect(() => {
    readFirebase("contacts")
      .then((res) => {
        setRegistrations(res);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <div>
      <p className="font-bold mb-5 text-lg">Registrations and contacts</p>
      {registrations &&
        registrations.length > 0 &&
        registrations.map((s) => {
          return (
            <div key={s?.id} className="bg-indigo-100 p-3 my-3 rounded">
              <p>Имя: {s?.data?.name}</p>
              <p>Email: {s?.data?.email}</p>
              <p>Сообщение: {s?.data?.message || "n/a"}</p>
              <p>Отправлено:{s?.data?.createdAt}</p>
              <div>
                <p>Название сессии: {s?.data?.session?.data?.title}</p>
                <p>Дата сессии: {s?.data?.session?.data?.date}</p>
                <p>Время сессии: {s?.data?.session?.data?.time}</p>
              </div>
            </div>
          );
        })}
    </div>
  );
}
