import React, { useState } from "react";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";
import { auth, provider } from "../../firebase";
import Input from "../../components/Input";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import { addFirebase, readFirebase } from "../../assets/firebaseFunctions";
import { signInWithPopup } from "firebase/auth";
import { loginWithGoogle } from "../../assets/authFunctions";
import GoogleBtn from "../../components/GoogleBtn";

export default function Register() {
  const navigate = useNavigate();
  const [user, setUser] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });
  const register = () => {
    if (user.password !== user.confirmPassword) {
      alert("Passwords do not match");
      return;
    } else {
      createUserWithEmailAndPassword(auth, user.email, user.password)
        .then((userCredential) => {
          // registered
          sendEmailVerification(auth.currentUser).then(() => {
            // Email verification sent!
            console.log("user register");
            addFirebase("users", {
              email: user.email,
              role: [
                "marina.kim.mk90@gmail.com",
                "vik.gorlanova@gmail.com",
                "maxim.lazyrin@gmail.com",
              ].includes(user.email)
                ? "admin"
                : "user",
            });
            navigate("/confirm-email");
          });
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log("Error: ", errorCode, errorMessage);
        });
    }
  };

  const signupWithGoogle = () => {
    setPersistence(auth, browserLocalPersistence).then(() => {
      signInWithPopup(auth, provider)
        .then(async (data) => {
          console.log(data.user);
          if (data.user.emailVerified) {
            console.log("user logged in with google: ", data.user.email);
            await readFirebase("users").then((users) => {
              if (
                users.filter((d) => d.data.email === data.user.email).length ===
                0
              ) {
                addFirebase("users", {
                  email: data.user.email,
                  role: [
                    "marina.kim.mk90@gmail.com",
                    "vik.gorlanova@gmail.com",
                    "maxim.lazyrin@gmail.com",
                  ].includes(data.user.email)
                    ? "admin"
                    : "user",
                });
              }
            });

            navigate("/");
          } else {
            alert("Please verify your email first");
          }
        })
        .catch((err) => {
          console.error(err);
          alert("Auth error: ", err.message);
        });
    });
  };

  return (
    <div>
      <Header login={true} />
      <div className="flex flex-col sm:w-1/3 w-80 m-auto mt-5">
        <Input
          placeholder="Email"
          type="email"
          name="email"
          value={user.email}
          onChange={(e) => setUser({ ...user, email: e.target.value })}
        />
        <Input
          placeholder="Password"
          type="password"
          name="password"
          value={user.password}
          onChange={(e) => setUser({ ...user, password: e.target.value })}
        />
        <Input
          placeholder="Confirm Password"
          type="password"
          name="confirmPassword"
          value={user.confirmPassword}
          onChange={(e) =>
            setUser({ ...user, confirmPassword: e.target.value })
          }
        />
        <button
          className="bg-teal-500 text-white p-3 mt-10 rounded flex justify-center"
          onClick={register}
        >
          Register
        </button>
        <button
          onClick={() => navigate("/login")}
          className="p-3 rounded flex justify-center underline text-slate-500"
        >
          Already have an account? Login
        </button>
        <p className="text-center mb-10 mt-5">or</p>
        <GoogleBtn
          loginWithGoogle={signupWithGoogle}
          btnText="Sign up with Google"
        />
      </div>
    </div>
  );
}
