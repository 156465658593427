import React, { useState, useEffect } from "react";
import Header from "../components/Header/Header";
import CustomTitle from "../components/CustomTitle";
import Footer from "../components/Footer";
import BackButton from "../components/BackButton";
import { readFirebase } from "../assets/firebaseFunctions";

export default function ReviewsPage() {
  const [events, setEvents] = useState([]);
  useEffect(() => {
    readFirebase("events").then((data) => {
      const filtered = data?.filter(
        (d) =>
          // new Date(d.data.date).getTime() < new Date().getTime() &&
          d.data.reviews?.filter((r) => r.status === "approved").length
      );
      console.log("filtered events: ", filtered);
      setEvents(filtered);
    });
  }, []);

  return (
    <div className="flex flex-col justify-between h-screen">
      <div>
        <Header />
        <div className="p-5">
          <div>
            <BackButton />
          </div>
          <CustomTitle text="Все отзывы" />

          {events
            // .filter((s) => s.data?.reviews?.length > 0 && s?.data?.review)
            // .flatMap((s) => s.data.reviews)
            .flatMap((event) => event.data.reviews)
            .filter((review) => review.status === "approved")
            .map((s, i) => {
              return (
                <div
                  key={i}
                  style={{ backgroundColor: "#DFE3E4" }}
                  className="my-3 rounded p-3"
                >
                  <p className="font-bold sm:text-lg text-md mb-3">
                    {s.eventName}
                  </p>
                  <p className="italic sm:text-lg text-sm mb-2">{s.review}</p>
                  <p className="text-sm">
                    {s.createdByName} | {s.createdAt}
                  </p>
                </div>
              );
            })}
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}
