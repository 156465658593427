import React from "react";
import VerifiedIcon from "@mui/icons-material/Verified";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";

export default function Banner() {
  const features = [
    {
      title: "Качество, основанное на знаниях и опыте",
      description:
        "Гарантируем высокий уровень профессионализма в каждом аспекте нашей работы",
      icon: <VerifiedIcon fontSize="large" />,
    },
    {
      title: "Бережное отношение и безопасность",
      description:
        "Обеспечиваем конфиденциальность и эмоциональную поддержку на всех этапах взаимодействия",
      icon: <VerifiedUserIcon fontSize="large" />,
    },
    {
      title: "Уважение личных границ и мнения",
      description:
        "Внимательно относимся к вашим запросам, потребностям, мнениям и убеждениям",
      icon: <VolunteerActivismIcon fontSize="large" />,
    },
  ];
  return (
    <div className="bg-white-500 text-center p-5">
      <div className="m-auto sm:mb-5 mb-2 sm:w-2/4 w-full">
        <h3 className="sm:text-2xl text-lg">
          Мы создаем безопасное пространство, где каждый чувствует себя ценным,
          принятым и понятым
        </h3>
      </div>
      <div className="flex flex-col justify-center items-center sm:flex-row text-white">
        {features.map((feature) => {
          return (
            <div
              key={feature.title}
              className="m-3 sm:p-4 p-3 sm:min-h-96 min-h-64 w-full sm:w-80 rounded flex flex-col items-center"
              style={{ backgroundColor: "#ABC7CA" }}
            >
              {feature.icon}
              <h3 className="font-bold my-3 sm:text-2xl text-lg leading-5">
                {feature.title}
              </h3>
              <p className="sm:text-2xl text-md leading-5">
                {feature.description}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
}
