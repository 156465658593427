import React, { useEffect } from "react";
import Header from "../components/Header/Header";
import { Link, useParams } from "react-router-dom";
import { readFirebase, updateFirebase } from "../assets/firebaseFunctions";
import { clearCart } from "../redux/slices/cartSlice";
import { auth } from "../firebase";

export default function OrderConfirmation() {
  const { orderId } = useParams();

  useEffect(() => {
    updateFirebase("orders", orderId, { status: "paid", orderNumber: orderId });
    readFirebase("orders").then((data) => {
      const events = data.filter((d) => d.id === orderId)[0].data.items;

      events.forEach((e) => {
        readFirebase("events").then((data) => {
          const event = data.filter((d) => d.id === e.eventId)[0];
          updateFirebase("events", event.id, {
            slots: parseInt(event.data.slots) - 1,
          });
        });
      });
    });
    clearCart();

    // send email with order confirmation and details
    // after domain and email setup
  }, []);

  return (
    <div>
      <Header />
      <div className="p-10 text-lg w-full md:w-1/3 m-auto">
        <h2 className="font-bold mb-10">Thank you for your order!</h2>
        <p className="italic mb-5">Your order number is: {orderId}</p>
        <p>We have send you an email with order details.</p>
        <p>You can view the history of orders in your profile .</p>

        <p className="my-5">
          <span className="font-bold">Important: </span>
          You need to complete the questionnaire before each session, so we can
          personalize the discussion for your specific case while remaining
          anonymous.
        </p>

        <p className="italic text-slate-500 mt-5">
          You can access the questionnaire in the "Upcoming events" section in
          your profile.
        </p>

        {auth?.currentUser?.email ? (
          <Link to="/profile">
            <button className="bg-teal-500 text-white p-3 rounded my-10">
              Go to profile
            </button>
          </Link>
        ) : (
          <Link to="/login">
            <button className="bg-teal-500 text-white p-3 rounded my-10">
              Login to see your profile
            </button>
          </Link>
        )}
      </div>
    </div>
  );
}
