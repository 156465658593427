import React from "react";
import Header from "../components/Header/Header";
import CustomTitle from "../components/CustomTitle";
import BackButton from "../components/BackButton";
import Footer from "../components/Footer";

export default function BusinessPage() {
  const services = [
    {
      id: 1,
      title: "Улучшение взаимодействия между топ-менеджерами",
      items: [
        "Фасилитация совещаний",
        "Консультации по разрешению конфликтов",
        "Обучение навыкам межличностного общения и эффективного донесения мыслей до коллег и клиентов",
      ],
    },
    {
      id: 2,
      title: "Формирование доверия в команде топ-менеджеров",
      items: [
        "Организация групповых сессий на развитие доверия и взаимопонимания",
        "Тренинги по эффективным коммуникациям и сотрудничеству",
      ],
    },
    {
      id: 3,
      title: "Подготовка и фасилитация стратегических сессий",
      items: [
        "Проведение стратегических сессий",
        "Тренинги по стратегическому мышлению",
        "Обучение использованию стратегических инструментов и методик на практике",
      ],
    },
    {
      id: 4,
      title: "Улучшение внутренних взаимоотношений и взаимодействия",
      items: [
        "Выявление и решение проблемных зон во взаимодействии",
        "Тренинги по эмоциональному интеллекту и управлению конфликтами",
        "Проведение дискуссионных площадок для разрешения конфликтных ситуаций",
      ],
    },
    {
      id: 5,
      title: "Повышение результативности команды",
      items: [
        "Анализ и устранение препятствий во взаимодействии",
        "Обучение новым способам взаимодействия и улучшение коммуникации",
        "Разработка программ повышения командной эффективности",
      ],
    },
    {
      id: 6,
      title: "Сопровождение перехода к стратегическому управлению",
      items: [
        "Поддержка при внедрении принципов делегирования и управления",
        "Консультирование по оптимизации управленческих процессов",
        "Обучение выявлению корневых проблем и их решению",
      ],
    },
    {
      id: 7,
      title: "Индивидуальная работа с сотрудниками и топ-менеджерами",
      items: [
        "Личные сессии для топ-менеджеров и ключевых сотрудников",
        "Персональные консультации по преодолению личных барьеров",
        "Поддержка в стрессовых ситуациях и принятии сложных решений",
      ],
    },
  ];

  const Service = (service) => {
    return (
      <div className="bg-[#DFE3E4] my-5 rounded p-5">
        <p className="font-bold text-[#CF9F4B] text-xl mb-3">{service.title}</p>
        <ul className="list-disc px-5">
          {service.items.map((item) => (
            <li>{item}</li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div>
      <Header currentTab="business" />
      <div className="max-w-4xl m-auto flex flex-col gap-5 py-10">
        <BackButton />
        <CustomTitle text="Для бизнеса" />
        <div>
          <p className="text-2xl font-bold mb-3">
            Групповые и персональные сессии для корпоративных клиентов
          </p>
          <p>
            Мы предлагаем корпоративным клиентам комплексные решения на основе
            психологии, ориентированные на:
            <br />
            <ul className="list-disc p-3">
              <li>улучшение командной работы</li>
              <li>разработку стратегий для руководителей</li>
              <li>
                повышение эффективности взаимодействия внутри команд и компании
              </li>
            </ul>
            Наши эксперты обладают более чем 15-летним опытом работы в крупных
            международных компаниях, включая топ-менеджерские позиции. Мы
            понимаем важность уникальных аспектов командной работы, выстраивания
            доверительных внутренних отношений и своевременного выявления
            проблемных зон. Наши программы адаптированы под конкретные
            потребности вашей компании, чтобы обеспечить достижение устойчивых
            результатов и укрепление корпоративной культуры.
          </p>
        </div>
        <div>
          {services.map((service) => (
            <Service {...service} />
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}
