import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { addFirebase } from "../../assets/firebaseFunctions";
import { auth } from "../../firebase";
import { storage } from "../../firebase";
import {
  getDownloadURL,
  ref as storageRef,
  uploadBytes,
} from "firebase/storage";

export default function NewEvent() {
  const [imageFile, setImageFile] = useState(null);
  const [isPublished, setIsPublished] = useState(false);
  const [isSoon, setIsSoon] = useState(false);
  const [isFree, setIsFree] = useState(false);
  const [errorsList, setErrorsList] = useState();
  const [numberOfCharacters, setNumberOfCharacters] = useState(0);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const uploadImage = async (file) => {
    if (!file) {
      alert("Please select an image");
      return null;
    }

    const imageRef = storageRef(storage, `images/${new Date().getTime()}`);
    try {
      const snapshot = await uploadBytes(imageRef, file);
      const url = await getDownloadURL(snapshot.ref);
      return url;
    } catch (error) {
      alert(error.message);
      console.log(error.message);
      return null;
    }
  };

  const onSubmit = async (data) => {
    console.log(data);
    if (!imageFile) {
      alert("Please upload an image");
      return;
    }

    try {
      const image = await uploadImage(imageFile);
      if (!image) {
        alert("Image upload failed");
        return;
      }

      const newEvent = {
        ...data,
        image,
        isPublished,
        isSoon,
        isFree,
        createdBy: auth.currentUser.email,
        createdAt: new Date().toDateString(),
      };

      console.log(newEvent);
      const id = await addFirebase("events", newEvent);
      console.log("event created", id);

      alert("Event created successfully!");
      reset();
      setImageFile(null);
    } catch (err) {
      console.log(err);
      alert("Error creating event: ", err.message);
    }
  };

  const handleImageUpload = (e) => {
    const img = e.target.files[0];
    console.log(img);
    setImageFile(img);
  };

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      console.log("Form Errors:", errors);
      setErrorsList(errors);
    }
  }, [errors]);

  const handleChangeDescription = (e) => {
    setNumberOfCharacters(e.target.value.length);
    setValue("description", e.target.value, { shouldValidate: true });
  };

  const description = watch("description", "");

  useEffect(() => {
    setNumberOfCharacters(description.length);
  }, [description]);

  return (
    <div>
      <p className="font-bold mb-5 text-lg">Create a new event</p>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="bg-slate-300 rounded flex flex-col gap-3 p-10 mb-10"
      >
        <input
          {...register("title", {
            required: true,
          })}
          placeholder="Title"
          className="p-3 rounded"
        />
        {/* {errors.title && <p className="text-red-500">Title is required</p>} */}
        {errors.description && errors.description.type === "maxLength" && (
          <p className="text-red-500">
            Description should be maximum 170 characters
          </p>
        )}
        <div className="bg-pink-300 relative">
          <input
            {...register("description", { required: true, maxLength: 170 })}
            placeholder="Description"
            className="py-3 pl-3 pr-20 rounded w-full"
            onChange={handleChangeDescription}
            defaultValue={description}
          />
          <p className={`absolute bottom-2 right-3 text-slate-500 text-xs`}>
            <span
              className={
                numberOfCharacters > 170
                  ? "text-red-500 font-bold"
                  : "text-slate-500"
              }
            >
              {numberOfCharacters}
            </span>
            /170
          </p>
        </div>
        {/* {errors.description && errors.description.type === "required" && (
          <p className="text-red-500">Description is required</p>
        )} */}

        <textarea
          {...register("detailedDescription")}
          placeholder="Detailed description"
          className="p-3 rounded"
        />
        <div className="flex justify-between gap-3">
          <input
            {...register("price", { required: !isFree })}
            placeholder="Price (USD)"
            className="p-3 rounded w-full"
          />
          <input
            {...register("slots", { required: true })}
            placeholder="Slots available"
            className="p-3 rounded w-full"
            type="number"
          />
        </div>
        <div className="flex flex-row gap-2 mb-3">
          <input
            type="checkbox"
            id="isFree"
            onChange={(e) => setIsFree(e.target.checked)}
          />
          <label htmlFor="isFree">Is free?</label>
        </div>

        <div className="flex gap-3 justify-between">
          <input
            {...register("date", { required: !isSoon })}
            placeholder="Date"
            className="p-3 rounded w-full"
            type="date"
            min={new Date().toISOString().split("T")[0]}
          />
          <input
            {...register("time", { required: !isSoon })}
            placeholder="Time"
            className="p-3 rounded w-full"
            type="time"
          />
        </div>

        <div className="flex flex-row gap-2 mb-3">
          <input
            type="checkbox"
            id="isSoon"
            onChange={(e) => setIsSoon(e.target.checked)}
          />
          <label htmlFor="isSoon">Coming soon?</label>
        </div>

        <div className="flex gap-3 justify-between">
          <input
            {...register("duration", { required: true })}
            placeholder="Duration"
            className="p-3 rounded w-full"
          />
          <input
            {...register("location", { required: true })}
            placeholder="Live video session (Google Meets)"
            defaultValue="Live video session (Google Meets)"
            className="p-3 rounded w-full"
          />
        </div>
        <div className="flex gap-3 justify-between">
          <select
            {...register("type", { required: true })}
            className="rounded w-1/2 p-3"
            placeholder="Test"
          >
            <option value="seminar" defaultValue>
              Select event type
            </option>
            <option value="seminar">Seminar</option>
            <option value="session">Online session</option>
            <option value="group">Group sessions</option>
          </select>
          <select
            {...register("client", { required: true })}
            className="rounded w-1/2 p-3"
            placeholder="Test"
          >
            <option value="seminar" defaultValue>
              Select client type
            </option>
            <option value="person">Person</option>
            <option value="company">Company</option>
          </select>
          <input
            {...register("speaker", { required: true })}
            placeholder="Speaker"
            className="p-3 rounded w-full"
          />
        </div>
        <input
          {...register("link", { required: true })}
          placeholder="Insert Google Meets link"
          className="p-3 rounded"
        />
        <input
          {...register("formLink", { required: false })}
          placeholder="Insert Google Form link"
          className="p-3 rounded"
        />
        <input
          {...register("additionalInfo", { required: false })}
          placeholder="Additional info"
          className="p-3 rounded"
        />
        <input
          {...register("additionalInfoLink", { required: false })}
          placeholder="Additional link"
          className="p-3 rounded"
        />
        <div className="flex flex-col gap-3 mt-3 bg-slate-200 p-3 rounded">
          <label htmlFor="avatar" className="font-bold">
            Upload a picture:
          </label>
          <input
            placeholder="Upload image"
            className="rounded"
            id="avatar"
            type="file"
            accept="image/png, image/jpeg, image/jpg"
            onChange={handleImageUpload}
          />
        </div>

        <div className="flex flex-row gap-2">
          <input
            type="checkbox"
            id="isPublished"
            onChange={(e) => setIsPublished(e.target.checked)}
          />
          <label htmlFor="isPublished">Show on main page?</label>
        </div>
        <button
          className="bg-teal-500 text-white p-3 rounded mt-3"
          type="submit"
        >
          Create event
        </button>
      </form>
    </div>
  );
}
