import Banner from "./components/Banner";
import Footer from "./components/Footer";
import Header from "./components/Header/Header";
import Reviews from "./components/Reviews";
import AllProducts from "./components/Products/AllProducts";
import Upcoming from "./components/Upcoming/Upcoming";

export default function App() {
  return (
    <div>
      <Header currentTab="main" />
      <Banner />
      <Upcoming />
      <AllProducts />
      <Reviews />
      <Footer />
    </div>
  );
}
