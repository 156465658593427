export const style_sm = {
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "justify-between",
  alignItems: "center",
  textAlign: "center",
  backgroundColor: "#DFE3E4",
  padding: 35,
  gap: 15,
};

export const style = {
  width: "25%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "justify-start",
  alignItems: "start",
  gap: 25,
  padding: 45,
  backgroundColor: "#DFE3E4",
};
