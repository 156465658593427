import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { addFirebase } from "../../assets/firebaseFunctions";
import { auth } from "../../firebase";
import { storage } from "../../firebase";
import {
  getDownloadURL,
  ref as storageRef,
  uploadBytes,
} from "firebase/storage";

export default function NewPost() {
  const { register, handleSubmit, reset } = useForm();
  const [imageFile, setImageFile] = useState(null);
  const [isPublished, setIsPublished] = useState(false);

  const uploadImage = async (file) => {
    if (!file) {
      alert("Please select an image");
      return null;
    }

    const imageRef = storageRef(storage, `images/${new Date().getTime()}`);
    try {
      const snapshot = await uploadBytes(imageRef, file);
      const url = await getDownloadURL(snapshot.ref);
      return url;
    } catch (error) {
      alert(error.message);
      console.log(error.message);
      return null;
    }
  };

  const onSubmit = async (data) => {
    if (!imageFile) {
      alert("Please upload an image");
      return;
    }

    try {
      const image = await uploadImage(imageFile);
      if (!image) {
        alert("Image upload failed");
        return;
      }

      const newPost = {
        ...data,
        image,
        isPublished,
        createdBy: auth.currentUser.email,
        createdAt: new Date().toDateString(),
      };

      console.log(newPost);
      const id = await addFirebase("posts", newPost);
      console.log("post created", id);

      alert("Post created successfully!");
      reset();
      setImageFile(null);
    } catch (err) {
      console.log(err);
      alert("Error creating post: ", err.message);
    }
  };

  const handleImageUpload = (e) => {
    const img = e.target.files[0];
    console.log(img);
    setImageFile(img);
  };

  return (
    <div>
      <p className="font-bold mb-5 text-lg">Create a new post</p>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="bg-slate-300 rounded flex flex-col gap-3 p-10 mb-10"
      >
        <input
          {...register("title", { required: true })}
          placeholder="Title"
          className="p-3 rounded"
        />
        <input
          {...register("subtitle", { required: true })}
          placeholder="Subtitle"
          className="p-3 rounded"
        />
        <textarea
          {...register("detailedDescription")}
          placeholder="Write here..."
          className="p-3 rounded"
        />
        <div className="flex flex-col gap-3 mt-3 bg-slate-200 p-3 rounded">
          <label htmlFor="avatar" className="font-bold">
            Upload a picture:
          </label>
          <input
            placeholder="Upload image"
            className="rounded"
            id="avatar"
            type="file"
            accept="image/png, image/jpeg, image/jpg"
            onChange={handleImageUpload}
          />
        </div>
        <div className="flex flex-row gap-2">
          <input
            type="checkbox"
            id="isPublished"
            onChange={(e) => setIsPublished(e.target.checked)}
          />
          <label htmlFor="isPublished">Show on main page?</label>
        </div>
        <button
          className="bg-teal-500 text-white p-3 rounded mt-5"
          type="submit"
        >
          Publish
        </button>
      </form>
    </div>
  );
}
