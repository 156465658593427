import Header from "../components/Header/Header";
import SessionCard from "../components/Upcoming/SessionCard";
import { Carousel } from "react-bootstrap";
import { useSelector } from "react-redux";
import Footer from "../components/Footer";
import { useWindowDimensions } from "../assets/hooks/customHooks";

export default function AllSessions() {
  const sessions = useSelector((state) => state.events.events);
  const { width } = useWindowDimensions();

  return (
    <div className="flex flex-col justify-between h-screen">
      <div>
        <Header currentTab="events" />

        <div className="sm:p-10 text-center md:max-w-[50%] m-auto">
          {sessions.filter(
            (s) =>
              s.data.type === "seminar" &&
              new Date(s.data.date).getTime() > new Date().getTime()
          ).length > 0 ? (
            <div className="pt-3">
              <h2 className="sm:text-3xl mb-3 text-xl font-bold">
                Психологические семинары
              </h2>
              <Carousel className="bg-[#ABC7CA] p-10">
                {sessions
                  .filter(
                    (s) =>
                      s.data.type === "seminar" &&
                      (new Date(s.data.date).getTime() > new Date().getTime() ||
                        s.data.isSoon)
                  )
                  .map((s) => {
                    return (
                      <Carousel.Item key={s.id}>
                        <div className="flex justify-center">
                          <SessionCard sessionData={s} from="events" />
                        </div>
                      </Carousel.Item>
                    );
                  })}
              </Carousel>
            </div>
          ) : (
            ""
          )}

          {sessions.filter(
            (s) =>
              s.data.type === "session" &&
              new Date(s.data.date).getTime() > new Date().getTime()
          ).length > 0 ? (
            <div>
              <h2 className="text-3xl font-bold mb-5 mt-10">
                Тематические групповые консультации
              </h2>
              <div className="flex flex-col justify-center sm:flex-row items-center content-start gap-5">
                {sessions
                  .filter(
                    (s) =>
                      s.data.type === "session" &&
                      new Date(s.data.date).getTime() > new Date().getTime()
                  )
                  .map((s) => {
                    return (
                      <div key={s.id}>
                        <SessionCard sessionData={s} />
                      </div>
                    );
                  })}
              </div>
            </div>
          ) : (
            ""
          )}

          {sessions.filter(
            (s) =>
              s.data.type === "group" &&
              new Date(s.data.date).getTime() > new Date().getTime()
          ).length > 0 ? (
            <div>
              <h2 className="text-3xl font-bold mb-5 mt-10">
                Терапевтические группы
              </h2>
              <div className="flex flex-col justify-center items-center sm:flex-row content-start gap-5">
                {sessions
                  .filter(
                    (s) =>
                      s.data.type === "group" &&
                      new Date(s.data.date).getTime() > new Date().getTime()
                  )
                  .map((s) => {
                    return (
                      <div key={s.id}>
                        <SessionCard sessionData={s} />
                      </div>
                    );
                  })}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}
