import { createSlice } from "@reduxjs/toolkit";

export const reviewSlice = createSlice({
  name: "review",
  initialState: {
    reviews: [],
  },
  reducers: {
    addReviewRedux: (state, action) => {
      state.reviews.push(action.payload);
    },
    removeReviewRedux: (state, action) => {
      state.reviews = state.reviews.filter((p) => p.id !== action.payload.id);
    },
    changeReviewStatusRedux: (state, action) => {
      const index = state.reviews.findIndex((p) => p.id === action.payload.id);
      state.reviews[index].data.status = action.payload.status;
    },
    setReviewsRedux: (state, action) => {
      state.reviews = action.payload;
    },
  },
});

export const {
  addReviewRedux,
  removeReviewRedux,
  changeReviewStatusRedux,
  getReviewsRedux,
  setReviewsRedux,
} = reviewSlice.actions;
export default reviewSlice.reducer;
