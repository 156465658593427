import React from "react";

export default function CustomButton({
  onClick = () => console.log("Button clicked"),
  text = "Button",
  type = "PRIMARY",
}) {
  // const PRIMARY = "bg-teal-500 text-white";
  // const SECONDARY = "text-white";
  // const TERTIARY = "underline text-slate-500";

  const primaryStylesBg = {
    backgroundColor: "#CF9F4B",
  };
  const secondaryStylesBg = {
    backgroundColor: "grey",
  };

  const primaryStylesText = {
    color: "white",
  };
  const secondaryStylesText = {
    color: "black",
  };

  return (
    <div className="text-center">
      <button
        className={"sm:w-80 w-60 rounded py-3 mt-10 text-white"}
        onClick={onClick}
        style={type === "PRIMARY" ? primaryStylesBg : secondaryStylesBg}
      >
        <p style={type === "PRIMARY" ? primaryStylesText : secondaryStylesText}>
          {text}
        </p>
      </button>
    </div>
  );
}
