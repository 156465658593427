import {
  collection,
  addDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { db } from "../firebase";

export const addFirebase = async (table, doc) => {
  try {
    const docRef = await addDoc(collection(db, table), doc);
    console.log("Document written with ID: ", docRef.id);
    return docRef.id;
  } catch (e) {
    console.error("Error adding document: ", e);
    return e;
  }
};

export const updateFirebase = async (table, id, updatedData) => {
  const docRef = doc(db, table, id);

  try {
    await updateDoc(docRef, updatedData);
    console.log("Document updated with ID: ", id);
    return id;
  } catch (e) {
    console.error("Error updating document: ", e);
    return e;
  }
};

export const readFirebase = async (table) => {
  const querySnapshot = await getDocs(collection(db, table));
  let docs = [];
  querySnapshot.forEach((doc) => {
    docs.push({
      id: doc.id,
      data: doc.data(),
    });
  });

  return docs;
};

export const deleteFirebase = async (table, id) => {
  try {
    await deleteDoc(doc(db, table, id));
    console.log("Document deleted with ID: ", id);
  } catch (err) {
    console.error("Error deleting document: ", err);
    return;
  }
};
