import React from "react";
import GoogleIcon from "@mui/icons-material/Google";

export default function GoogleBtn({
  loginWithGoogle = () => console.log("callback fn"),
  btnText = "Google button",
}) {
  return (
    <button
      onClick={loginWithGoogle}
      className="shadow-lg py-3 flex justify-center items-center gap-3 border-2 rounded"
    >
      <GoogleIcon />
      {btnText}
    </button>
  );
}
