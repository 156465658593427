import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../components/Header/Header";
import Bread from "../components/Bread";
import { readFirebase } from "../assets/firebaseFunctions";
import BackButton from "../components/BackButton";
import { auth } from "../firebase";

export default function PostPage() {
  const [post, setPost] = useState();
  const { postId } = useParams();

  useEffect(() => {
    readFirebase("posts").then((data) => {
      setPost(data.find((s) => s.data.title === postId));
    });
  }, []);

  return (
    <div>
      <Header />
      <div className="p-10 max-w-4xl m-auto">
        <BackButton />
        <div className="flex flex-col gap-5 mt-5">
          <div>
            <h2 className="text-3xl font-bold">{post?.data.title}</h2>
            <p className="text-xl font-bold my-5">{post?.data.subtitle}</p>
            <p className="italic text-slate-500 my-5">
              {post?.data.createdAt} | {post?.data.createdBy}
            </p>
            {["marina.kim.mk90@gmail.com"].includes(auth?.currentUser?.email)}
            <div className="">
              <img
                src={post?.data.image}
                alt="Product"
                className="rounded-lg object-cover mb-10"
              />
            </div>
            {post?.data?.detailedDescription ? (
              <div className="mt-3">
                {post?.data?.detailedDescription.split("/n").map((x, i) => (
                  <p className="my-3" key={i}>
                    {x}
                  </p>
                ))}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
