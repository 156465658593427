import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="bg-slate-800 text-white text-center py-10 w-full">
      <p>2024 | Created by MV Revival</p>
      <div className="flew flex-row justify-center items-center gap-3">
        <Link to="/payment-terms-and-conditions" className="mr-3 underline">
          Условия оплаты и возврата
        </Link>
        <Link to="/privacy-policy" className="underline">
          Политика конфиденциальности
        </Link>
      </div>
    </div>
  );
}
