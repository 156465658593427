import React, { useEffect, useState } from "react";
import Header from "../components/Header/Header";
import Bread from "../components/Bread";
import { readFirebase } from "../assets/firebaseFunctions";
import PostPage from "./PostPage";
import Post from "../components/Post";
import BackButton from "../components/BackButton";
import CustomTitle from "../components/CustomTitle";

export default function Posts() {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    readFirebase("posts").then((data) => {
      setPosts(data);
    });
  }, []);

  return (
    <div>
      <Header currentTab="blog" />
      <div className="max-w-7xl m-auto flex flex-col gap-5 py-10">
        <BackButton />
        <CustomTitle text="Эта страница еще в разработке 🥲" />
        {/* <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {posts.map((post) => {
            return (
              <div key={post.id}>
                <Post postData={post} />
              </div>
            );
          })}
        </div> */}
      </div>
    </div>
  );
}
