import { createSlice } from "@reduxjs/toolkit";

export const eventsSlice = createSlice({
  name: "events",
  initialState: {
    events: [],
  },
  reducers: {
    setEventsRedux: (state, action) => {
      state.events = action.payload;
    },
    addToWishListRedux: (state, action) => {
      const index = state.events.findIndex((e) => e.id === action.payload.id);

      state.events[index].data.wishlisted = [
        ...state.events[index].data.wishlisted,
        action.payload.user,
      ];
    },
    removeFromWishListRedux: (state, action) => {
      const index = state.events.findIndex((e) => e.id === action.payload.id);

      state.events[index].data.wishlisted = state.events[
        index
      ].data.wishlisted.filter((w) => w !== action.payload.user);
    },
    updateEventsRedux: (state, action) => {
      const index = state.events.findIndex((e) => e.id === action.payload.id);

      state.events[index].data = action.payload.data;
    },
  },
});

export const {
  setEventsRedux,
  addToWishListRedux,
  removeFromWishListRedux,
  updateEventsRedux,
} = eventsSlice.actions;
export default eventsSlice.reducer;
