import { useNavigate } from "react-router-dom";
import { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

export default function NavbarSmall({ user, current }) {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  if (!isOpen) {
    return (
      <div className="flex flex-row justify-end">
        <MenuIcon onClick={() => setIsOpen(true)} fontSize="large" />
      </div>
    );
  }

  return (
    <div>
      <div className="bg-[#DFE3E4] absolute h-screen top-0 left-0 w-[70%] pt-[70px] px-5 border-r-2 border-[#CF9F4B]">
        <CloseIcon
          onClick={() => setIsOpen(false)}
          fontSize="large"
          className="absolute top-5 right-5"
        />
        <div className="flex flex-col justify-between items-start">
          {[
            "marina.kim.mk90@gmail.com",
            "vik.gorlanova@gmail.com",
            "maxim.lazyrin@gmail.com",
          ].includes(user?.email) && (
            <button
              onClick={() => navigate("/admin")}
              className={`${
                current === "admin" ? "underline font-bold" : undefined
              } navbar-text`}
            >
              Админ
            </button>
          )}
          <button
            onClick={() => navigate("/")}
            className={`flex navbar-text min-w-10 whitespace-nowrap ${
              current === "main" ? "underline font-bold" : undefined
            }`}
          >
            Главная
          </button>
          <button
            onClick={() => navigate("/about")}
            className={`flex navbar-text min-w-10 whitespace-nowrap ${
              current === "about" ? "underline font-bold" : undefined
            }`}
          >
            О нас
          </button>
          <button
            onClick={() => navigate("/sessions")}
            className={`navbar-text ${
              current === "events" ? "underline font-bold" : undefined
            }`}
          >
            События
          </button>
          <button
            onClick={() => navigate("/business")}
            className={`flex navbar-text min-w-10 whitespace-nowrap ${
              current === "business" ? "underline font-bold" : undefined
            }`}
          >
            Для бизнеса
          </button>
          <button
            onClick={() => navigate("/contacts")}
            className={`navbar-text ${
              current === "contacts" ? "underline font-bold" : undefined
            }`}
          >
            Контакты
          </button>
        </div>
      </div>
    </div>
  );
}
