export default function Input({ placeholder, type, name, value, onChange }) {
  return (
    <input
      className="bg-slate-100 p-3 mb-2 rounded"
      placeholder={placeholder}
      type={type}
      name={name}
      value={value}
      onChange={onChange}
    />
  );
}
