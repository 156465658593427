import Logo from "./Logo";
import ProfileIcon from "./ProfileIcon";
import Navbar from "./Navbar";
import CartIcon from "./CartIcon";
import { useNavigate } from "react-router-dom";

export default function HeaderSmall({ currentTab, user, logout, cart }) {
  const navigate = useNavigate();
  return (
    <div
      className="z-20 px-10 pt-3 sticky top-0"
      style={{ borderBottom: "3px solid #CF9F4B", backgroundColor: "#DFE3E4" }}
    >
      <div className="flex flex-col">
        <Logo />
        <div className="flex flex-row justify-between items-center">
          <Navbar currentTab={currentTab} />
          <div className="flex flex-row gap-1">
            <button
              className={`${
                currentTab === "login" ? "underline font-bold" : undefined
              }`}
              onClick={user ? logout : () => navigate("/login")}
            >
              {user ? "Выйти" : "Войти"}
            </button>
            <ProfileIcon />
          </div>
        </div>
        <div className="flex justify-between items-center mb-3">
          <div className="flex items-center gap-3">
            {cart?.length > 0 && <CartIcon cartLength={cart.length} />}
          </div>
        </div>
      </div>
    </div>
  );
}
