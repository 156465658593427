import { useSessions } from "../../assets/hooks/customHooks";

export default function Wishlisted() {
  const { sessions } = useSessions();
  const sendEmail = (recipients) => {
    console.log("Send email to recipients: ", recipients);
  };

  return (
    <div>
      <p className="font-bold mb-5 text-lg">Wishlisted events</p>
      {sessions &&
        sessions.length > 0 &&
        sessions
          .filter((s) => s.data.wishlisted && s.data.wishlisted.length)
          .sort((a, b) => a.data.wishlisted.length - b.data.wishlisted.length)
          .map((s) => {
            return (
              <div className="bg-indigo-100 p-3 rounded">
                <p className="font-bold">
                  Event: {s.data.title} | {s.data.wishlisted.length}
                </p>
                <div>
                  {s.data?.wishlisted?.length > 0 &&
                    s.data.wishlisted.map((w) => {
                      return <p>- {w}</p>;
                    })}
                </div>
                <button
                  onClick={() => sendEmail(s.data.wishlisted)}
                  className="text-slate-500 underline my-3"
                >
                  Send availability notification
                </button>
              </div>
            );
          })}
    </div>
  );
}
