import React from "react";
import { Link } from "react-router-dom";
import { useWindowDimensions } from "../../assets/hooks/customHooks";

export default function Logo() {
  const { width } = useWindowDimensions();

  return (
    <Link to="/">
      {width > 736 ? (
        <div className="text-center flex flex-col items-center">
          <img
            alt="colored logo"
            src={require("./logocolorh.png")}
            style={{ height: "80px" }}
          />
        </div>
      ) : (
        <div className="text-center flex flex-col items-center">
          <img
            alt="colored logo"
            src={require("./logocolorh_cropped.png")}
            className="h-[50px]"
          />
        </div>
      )}
    </Link>
  );
}
