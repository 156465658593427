import Header from "../components/Header/Header";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import PaymentIcon from "@mui/icons-material/Payment";
import { removeFromCart } from "../redux/slices/cartSlice";
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";
import CartItem, { PaymentItem } from "../components/CartItem";
import { usePrice } from "../assets/hooks/customHooks";
import Upcoming from "../components/Upcoming/Upcoming";
import { useState } from "react";

export default function Cart() {
  const navigate = useNavigate();
  const cart = useSelector((state) => state.cart.products);
  const dispatch = useDispatch();
  const { price } = usePrice(
    cart.reduce((acc, item) => acc + parseInt(item.data.price), 0)
  );
  const [checked, setChecked] = useState(false);

  const handleGuestCheckout = () => {
    if (checked) {
      navigate("/payment", { state: { guest: true } });
    } else {
      alert("Please agree to the Terms of Use");
    }
  };

  return (
    <div>
      <Header />
      <div className="p-10 2xl:w-1/2 w-full mx-auto">
        <h2 className="text-3xl font-bold mb-10 text-center">Cart</h2>
        {cart?.length > 0 ? (
          <div className="flex lg:flex-row flex-col justify-between gap-5">
            <div className="flex flex-col justify-start items-start content-start 2xl:w-2/3 w-full gap-3">
              {cart.map((item) => {
                return (
                  <CartItem
                    item={item}
                    removeFn={() => dispatch(removeFromCart(item.id))}
                  />
                );
              })}
            </div>

            <div className="bg-slate-100 2xl:w-1/3 w-full p-10 rounded">
              <p className="text-center text-lg font-bold">Payment summary</p>
              <div className="flex flex-col mt-5">
                {cart.map((item, index) => {
                  return (
                    <div key={index}>
                      <PaymentItem item={item} />
                    </div>
                  );
                })}
              </div>

              <div
                className="pb-5"
                style={{ borderBottom: "1px solid #2d2d2d" }}
              >
                <p className="flex justify-between mt-5">
                  <span className="font-bold text-xl">Total:</span>
                  <span className="font-bold text-xl">{price}</span>
                </p>
              </div>
              {auth?.currentUser?.email ? (
                <div className="w-full bg-blue-500 p-3 text-white mt-10 rounded">
                  <Link
                    to="/payment"
                    className="flex justify-center items-center"
                  >
                    <PaymentIcon className="mr-3" />
                    <p>Proceed to payment</p>
                  </Link>
                </div>
              ) : (
                <div className="w-full text-center mt-10 flex flex-col gap-3">
                  <button
                    onClick={() =>
                      navigate("/login", { state: { from: "cart" } })
                    }
                    className="text-white p-3 rounded w-full"
                    style={{ backgroundColor: "#CF9F4B" }}
                  >
                    <p>Login to checkout</p>
                  </button>
                  <p className="text-slate-500">--- or ---</p>
                  <button
                    className="flex justify-center items-center p-3 w-full rounded"
                    style={{ backgroundColor: "#DFE3E4" }}
                    onClick={handleGuestCheckout}
                  >
                    <PaymentIcon className="mr-3" />
                    <p>Checkout as guest</p>
                  </button>
                  <div className="flex flex-row gap-1 justify-start items-center">
                    <input
                      type="checkbox"
                      id="consent"
                      name="consent"
                      value={checked}
                      onChange={(e) => setChecked(e.target.checked)}
                    />
                    <label htmlFor="consent">
                      I agree with{" "}
                      <Link className="text-blue-500 underline">
                        Terms of Use
                      </Link>
                    </label>
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div>Your cart is empty</div>
        )}
        <div className="mt-10">
          <Upcoming cart={true} />
        </div>
      </div>
    </div>
  );
}
