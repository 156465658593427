import { deleteFirebase } from "../../assets/firebaseFunctions";
import { useSessions } from "../../assets/hooks/customHooks";
import { useNavigate } from "react-router-dom";

export default function AllEvents() {
  const navigate = useNavigate();
  const { sessions } = useSessions();

  const handleDelete = async (id) => {
    console.log("delete", id);
    try {
      await deleteFirebase("events", id);
      alert("Event deleted successfully");
      window.location.reload();
    } catch (err) {
      alert(err.message);
      console.log(err.message);
    }
  };

  return (
    <div>
      <p className="font-bold mb-5 text-lg">All events</p>
      {sessions.length ? (
        <div className="overflow-auto h-[500px]">
          {sessions.map((session, i) => {
            return (
              <div key={i} className="bg-slate-200 p-3 my-3 rounded">
                <p>{session?.data?.title}</p>
                <p className="text-slate-500 italic">
                  {session?.data?.client} | {session?.data?.type}
                </p>
                <p>{session?.data?.date}</p>
                <p>
                  {session?.data?.isPublished ? "Published" : "Not published"}
                </p>
                <div className="flex flex-row gap-2 mt-3">
                  <button
                    className="bg-sky-500 text-white py-1 px-3 rounded"
                    onClick={() =>
                      navigate("/change-event", { state: { event: session } })
                    }
                  >
                    Edit
                  </button>
                  <button
                    className="bg-orange-500 text-white py-1 px-3 rounded"
                    onClick={() => handleDelete(session.id)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <p>No events found</p>
      )}
    </div>
  );
}
