import { loadStripe } from "@stripe/stripe-js";

let stripePromise;

const getStripe = () => {
  if (!stripePromise) {
    // stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
    stripePromise = loadStripe(
      "pk_test_51OltDCC8OwPE2pR3GV6AejWdIp83lwQnwbzYCPXbAnv10b662D2NviwPg54ZdW417x9SKVqdAgr0ZNG8m0QSGaLP00Aks17rrA"
    );
  }

  return stripePromise;
};

export default getStripe;
