import { createSlice } from "@reduxjs/toolkit";

export const faqsSlice = createSlice({
  name: "faqs",
  initialState: {
    faqs: [],
  },
  reducers: {
    setFaqsRedux: (state, action) => {
      state.faqs = action.payload;
    },
    updateFaqsRedux: (state, action) => {
      const index = state.faqs.findIndex((e) => e.id === action.payload.id);

      state.faqs[index].data = action.payload.data;
    },
  },
});

export const { setFaqsRedux, updateFaqsRedux } = faqsSlice.actions;
export default faqsSlice.reducer;
