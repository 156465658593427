import Logo from "./Logo";
import Navbar from "./Navbar";
import CartIcon from "./CartIcon";
import ProfileIcon from "./ProfileIcon";

export default function HeaderBig({ currentTab, cart }) {
  return (
    <div
      className="z-20 px-10 flex justify-between items-center sticky top-0"
      style={{ borderBottom: "3px solid #CF9F4B", backgroundColor: "#DFE3E4" }}
    >
      <Logo />
      <div className="flex gap-5  w-80 justify-end">
        {cart?.length > 0 && <CartIcon cartLength={cart.length} />}
        <Navbar currentTab={currentTab} />
        <ProfileIcon />
      </div>
    </div>
  );
}
