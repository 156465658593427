import React from "react";
import Header from "../components/Header/Header";
import CustomTitle from "../components/CustomTitle";
import Footer from "../components/Footer";
import { useForm } from "react-hook-form";
import { auth } from "../firebase";
import { updateFirebase } from "../assets/firebaseFunctions";
import { useSessions } from "../assets/hooks/customHooks";
import { useNavigate } from "react-router-dom";

export default function LeaveReview() {
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm();
  const { sessions } = useSessions();

  const onSubmit = async (data) => {
    console.log("submitting new review");
    const newReview = {
      ...data,
      ...{
        createdBy: auth?.currentUser?.email || data.createdByEmail || "n/a",
        createdByName: data.createdByName || "Anonymous",
        createdAt: new Date().toDateString(),
        eventId: data.event.split("-")[0],
        eventName: data.event.split("-")[1],
        status: "created",
        type: "general",
      },
    };

    try {
      let event = sessions.filter((s) => s.id === data.event.split("-")[0])[0];
      await updateFirebase("events", data.event.split("-")[0], {
        reviews: event.data.reviews
          ? [...event.data.reviews, newReview]
          : [newReview],
      });

      alert("Thank you for submitted review!");
      navigate("/");
    } catch (err) {
      console.log(err);
      alert("Error submitting review: ", err.message);
    }
  };

  return (
    <div className="flex flex-col justify-between h-screen">
      <div>
        <Header />
        <div className="w-full sm:w-2/4 m-auto">
          <CustomTitle text="Будем благодарны, если оставите отзыв 😮‍💨" />
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col bg-slate-300 gap-3 p-10 mb-10"
          >
            <select
              {...register("event", { required: true })}
              className="rounded w-full p-3"
              placeholder="Test"
            >
              <option value="seminar" defaultValue>
                Выберите событие
              </option>
              {[...sessions]
                .sort(
                  (a, b) =>
                    new Date(a.data.date).getTime() -
                    new Date(b.data.date).getTime()
                )
                .map((event) => (
                  <option
                    value={`${event.id}-${event.data.title}`}
                    key={event.id}
                  >
                    {event.data.title}
                  </option>
                ))}
              {/* <option>Other</option> */}
            </select>
            <textarea
              {...register("review", { required: true })}
              placeholder="Отзыв*"
              className="p-3 rounded"
            />
            <input
              {...register("createdByName", { required: false })}
              placeholder="Имя (необязательно)"
              className="p-3 rounded"
            />
            <input
              {...register("createdByEmail", { required: false })}
              placeholder="Email (необязательно)"
              className="p-3 rounded"
            />
            <button
              className="bg-teal-500 text-white p-3 rounded mt-5"
              type="submit"
            >
              Оставить отзыв
            </button>
          </form>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}
