import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { auth } from "../firebase";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../components/Header/Header";
import Bread from "../components/Bread";
import { updateFirebase } from "../assets/firebaseFunctions";
import { useDispatch } from "react-redux";
import { updateEventsRedux } from "../redux/slices/eventsSlice";
import { storage } from "../firebase";
import {
  getDownloadURL,
  ref as storageRef,
  uploadBytes,
} from "firebase/storage";

export default function ChangeEvent() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [imageFile, setImageFile] = useState(null);
  const [isPublished, setIsPublished] = useState(
    state?.event?.data?.isPublished
  );
  const [isSoon, setIsSoon] = useState(state?.event?.data?.isSoon);
  const [isFree, setIsFree] = useState(state?.event?.data?.isFree);

  const preloadedValues = {
    title: state?.event?.data?.title,
    description: state?.event?.data?.description,
    detailedDescription: state?.event?.data?.detailedDescription,
    price: state?.event?.data?.price,
    slots: state?.event?.data?.slots,
    date: state?.event?.data?.date,
    time: state?.event?.data?.description,
    duration: state?.event?.data?.duration,
    location: state?.event?.data?.location,
    type: state?.event?.data?.type,
    speaker: state?.event?.data?.speaker,
    link: state?.event?.data?.link,
    formLink: state?.event?.data?.formLink,
    image: state?.event?.data?.image,
    isPublished: state?.event?.data?.isPublished,
    isSoon: state?.event?.data?.isSoon,
    isFree: state?.event?.data?.isFree,
    additionalInfo: state?.event?.data?.additionalInfo,
    additionalInfoLink: state?.event?.data?.additionalInfoLink,
  };

  const uploadImage = async (file) => {
    if (!file) {
      alert("Please select an image");
      return null;
    }

    const imageRef = storageRef(storage, `images/${new Date().getTime()}`);
    try {
      const snapshot = await uploadBytes(imageRef, file);
      const url = await getDownloadURL(snapshot.ref);
      return url;
    } catch (error) {
      alert(error.message);
      console.log(error.message);
      return null;
    }
  };

  const { register, handleSubmit } = useForm({
    defaultValues: preloadedValues,
  });

  const onSubmit = async (data) => {
    let image;
    if (imageFile) {
      image = await uploadImage(imageFile);
      if (!image) {
        alert("Image upload failed");
        return;
      }
    } else {
      image = state?.event?.data?.image;
    }

    if (!image) {
      alert("Image upload failed");
      return;
    }

    const updatedEvent = {
      ...data,
      image,
      isPublished,
      isSoon,
      isFree,
      ...{
        createdBy: auth.currentUser.email,
        createdAt: new Date().toDateString(),
      },
    };
    console.log("updated event", updatedEvent);
    updateFirebase("events", state?.event?.id, updatedEvent);
    //update in redux
    dispatch(updateEventsRedux({ id: state?.event?.id, data: updatedEvent }));

    alert("Event updated successfully");
    navigate("/sessions");
  };

  const handleImageUpload = (e) => {
    const img = e.target.files[0];
    console.log(img);
    setImageFile(img);
  };

  return (
    <div>
      <Header />
      <Bread />
      <div className="m-10">
        <p className="font-bold mb-5 text-lg">Update event</p>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="bg-slate-300 rounded flex flex-col gap-3 p-10 mb-10"
        >
          <input
            {...register("title", { required: true })}
            placeholder="Title"
            className="p-3 rounded"
          />
          <input
            {...register("description", { required: true })}
            placeholder="Description"
            className="p-3 rounded"
          />
          <textarea
            {...register("detailedDescription")}
            placeholder="Detailed description"
            className="p-3 rounded"
          />
          <div className="flex justify-between gap-3">
            <input
              {...register("price", { required: !isFree })}
              placeholder="Price (tenge)"
              className="p-3 rounded w-full"
            />
            <input
              {...register("slots", { required: true })}
              placeholder="Slots available"
              className="p-3 rounded w-full"
            />
          </div>

          <div className="flex flex-row gap-2 mb-3">
            <input
              type="checkbox"
              id="isFree"
              onChange={(e) => setIsFree(e.target.checked)}
              checked={isFree}
            />
            <label htmlFor="isFree">Is free?</label>
          </div>

          <div className="flex gap-3 justify-between">
            <input
              {...register("date", { required: !isSoon })}
              placeholder="Date"
              className="p-3 rounded w-full"
              type="date"
              min={new Date().toISOString().split("T")[0]}
            />
            <input
              {...register("time", { required: !isSoon })}
              placeholder="Time"
              className="p-3 rounded w-full"
              type="time"
            />
          </div>

          <div className="flex flex-row gap-2 mb-3">
            <input
              type="checkbox"
              id="isSoon"
              onChange={(e) => setIsSoon(e.target.checked)}
              checked={isSoon}
            />
            <label htmlFor="isSoon">Coming soon?</label>
          </div>

          <div className="flex gap-3 justify-between">
            <input
              {...register("duration", { required: true })}
              placeholder="Duration"
              className="p-3 rounded w-full"
            />
            <input
              {...register("location", { required: true })}
              placeholder="Live video session (Google Meets)"
              className="p-3 rounded w-full"
            />
          </div>
          <div className="flex gap-3 justify-between">
            <select
              {...register("type", { required: true })}
              className="rounded w-1/2 p-3"
              placeholder="Test"
            >
              <option value="seminar" defaultValue>
                Select event type
              </option>
              <option value="seminar">Seminar</option>
              <option value="session">Online session</option>
            </select>
            <input
              {...register("speaker", { required: true })}
              placeholder="Speaker"
              className="p-3 rounded w-full"
            />
          </div>
          <input
            {...register("link", { required: true })}
            placeholder="Insert Google Meets link"
            className="p-3 rounded"
          />
          <input
            {...register("formLink", { required: false })}
            placeholder="Insert Google Form link"
            className="p-3 rounded"
          />
          <input
            {...register("additionalInfo", { required: false })}
            placeholder="Additional info"
            className="p-3 rounded"
          />
          <input
            {...register("additionalInfoLink", { required: false })}
            placeholder="Additional link"
            className="p-3 rounded"
          />
          <p>Current image:</p>
          <input
            {...register("image", { required: true })}
            placeholder="Upload image"
            className="p-3 rounded"
          />
          <div className="flex flex-col gap-3 mt-3 bg-slate-200 p-3 rounded">
            <label htmlFor="avatar" className="font-bold">
              Upload a picture:
            </label>
            <input
              placeholder="Upload image"
              className="rounded"
              id="avatar"
              type="file"
              accept="image/png, image/jpeg, image/jpg"
              onChange={handleImageUpload}
            />
          </div>
          <div className="flex flex-row gap-2 mt-3">
            <input
              type="checkbox"
              id="isPublished"
              checked={isPublished}
              onChange={(e) => setIsPublished(e.target.checked)}
            />
            <label htmlFor="isPublished">Show on main page?</label>
          </div>
          <button
            className="bg-teal-500 text-white p-3 rounded mt-5"
            type="submit"
          >
            Update event
          </button>
        </form>
      </div>
    </div>
  );
}
