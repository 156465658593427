import { readFirebase, deleteFirebase } from "../../assets/firebaseFunctions";
import { useNavigate } from "react-router-dom";
import { useFaqs } from "../../assets/hooks/customHooks";

export default function AllFaqs() {
  const navigate = useNavigate();
  const { faqs } = useFaqs();

  const handleDeletePost = async (id) => {
    try {
      await deleteFirebase("faqs", id);
      alert("FAQ deleted successfully");
      window.location.reload();
    } catch (err) {
      alert(err.message);
      console.log(err.message);
    }
  };

  return (
    <div>
      <p className="font-bold mb-5 text-lg">All posts</p>
      {faqs && faqs.length ? (
        <div>
          {faqs.map((post, i) => {
            return (
              <div key={i} className="bg-slate-200 p-3 my-3 rounded">
                <p className="text-lg font-bold">{post?.data?.question}</p>
                <p>{post?.data?.answer}</p>
                <p className="text-slate-500 italic">
                  {post?.data?.createdBy} | {post?.data?.createdAt}
                </p>
                <div className="flex flex-row gap-2 mt-3">
                  <button
                    className="bg-sky-500 text-white py-1 px-3 rounded"
                    onClick={() =>
                      navigate("/change-faq", { state: { faq: post } })
                    }
                  >
                    Edit
                  </button>
                  <button
                    className="bg-orange-500 text-white py-1 px-3 rounded"
                    onClick={() => handleDeletePost(post.id)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <p>No posts found</p>
      )}
    </div>
  );
}
