import SessionCard from "./SessionCard";
import { useSessions } from "../../assets/hooks/customHooks";

export default function SessionList() {
  const cart = false;
  const { sessions } = useSessions();

  return (
    <div className="flex flex-col sm:flex-row justify-center items-center gap-5">
      {cart ? (
        <div>TBD</div>
      ) : (
        sessions
          .filter(
            (s) =>
              new Date(s.data.date).getTime() > new Date().getTime() &&
              s.data.client === "person" &&
              s.data.isPublished
          )
          .sort((a, b) => new Date(a.data.date) - new Date(b.data.date))
          .splice(0, 3)
          .map((session) => {
            return (
              <div key={session.id}>
                <SessionCard sessionData={session} />
              </div>
            );
          })
      )}
    </div>
  );
}
